import React from 'react';
import './App.css';

import {
  BrowserRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Home from './pages/customer/Home';
import Inventory from './pages/customer/Inventory';
import Delivery from './pages/customer/Delivery';
import NewDelivery from './pages/customer/NewDelivery';

import Addresses from './pages/customer/Addresses';
import Address from './pages/customer/Address';
import Account from './pages/customer/Account';
import Payments from './pages/customer/Payments';

import AdminDashboard from './pages/admin/Admin';
import ReshipperAdmin from './pages/admin/ReshipperProfile';
import ReshippersAdmin from './pages/admin/Reshippers';
import LogsAdmin from './pages/admin/Logs';
import UsersAdmin from './pages/admin/Users';
import UserAdmin from './pages/admin/CustomerProfile';

import ReshipperAccount from './pages/reshipper/Account';
import ReshipperDashboard from './pages/reshipper/Home';
import ReshipperExpectedDelivery from './pages/reshipper/ExpectedDelivery';
import ReshipperDelivered from './pages/reshipper/Delivered';
import ReshipperInventory from './pages/reshipper/Inventory';
import ReshipperPayments from './pages/reshipper/Payments';
import ReshipperActions from './pages/reshipper/Actions';

import Login from './pages/global/Login';
import Logout from './pages/global/Logout';

import {
  ProtectedRoute, AuthProvider, LogInRoute, useAuth,
} from './providers/AuthProvider';

import { request } from './functions/request';

const theme = createTheme({
  palette: {
    primary: {
      light: '#426eea',
      main: '#134AE5',
      dark: '#0d33a0',
      contrastText: '#F2F2F2',
    },
    secondary: {
      light: '#344b63',
      main: '#021F3D',
      dark: '#01152a',
      contrastText: '#F2F2F2',
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />

            <Route
              exact
              path="/login"
              element={(
                <AuthProvider>
                  <LogInRoute>
                    <Login />
                  </LogInRoute>
                </AuthProvider>
              )}
            />
            <Route
              exact
              path="/logout"
              element={(
                <AuthProvider>
                  <Logout />
                </AuthProvider>
              )}
            />
            <Route
              exact
              path="/login/callback"
              element={(
                <AuthProvider>
                  <LoginCallback />
                </AuthProvider>
              )}
            />

            <Route
              element={(
                <AuthProvider>
                  <ProtectedRoute userRole="customer" />
                </AuthProvider>
              )}
            >
              <Route exact path="/" element={<Home />} />
              <Route exact path="/account" element={<Account />} />
              <Route exact path="/inventory" element={<Inventory />} />
              <Route exact path="/delivery" element={<NewDelivery />} />
              <Route path="/delivery/:deliveryId" element={<Delivery />} />

              <Route exact path="/addresses" element={<Addresses />} />
              <Route exact path="/address/:addressId" element={<Address />} />
              <Route exact path="/payments" element={<Payments />} />
            </Route>

            <Route
              element={(
                <AuthProvider>
                  <ProtectedRoute userRole="reshipper" />
                </AuthProvider>
              )}
            >
              <Route exact path="/reshipper" element={<ReshipperDashboard />} />
              <Route exact path="/reshipper/account" element={<ReshipperAccount />} />
              <Route exact path="/reshipper/delivered" element={<ReshipperDelivered />} />
              <Route
                exact
                path="/reshipper/delivery/:deliveryId"
                element={<ReshipperExpectedDelivery />}
              />
              <Route exact path="/reshipper/inventory" element={<ReshipperInventory />} />
              <Route exact path="/reshipper/payments" element={<ReshipperPayments />} />
              <Route exact path="/reshipper/actions" element={<ReshipperActions />} />
            </Route>

            <Route
              element={(
                <AuthProvider>
                  <ProtectedRoute userRole="admin" />
                </AuthProvider>
              )}
            >
              <Route exact path="/admin" element={<AdminDashboard />} />
              <Route path="/admin/logs" element={<LogsAdmin />} />
              <Route exact path="/admin/users" element={<UsersAdmin />} />
              <Route path="/admin/users/:userId" element={<UserAdmin />} />
              <Route exact path="/admin/reshippers" element={<ReshippersAdmin />} />
              <Route path="/admin/reshippers/:reshipperId" element={<ReshipperAdmin />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

function LoginCallback() {
  const { login } = useAuth();

  request('/util/me', {})
    .then((data) => {
      login(data);
    })
    .catch(() => {
      // do nothing
    });
}

export default App;
