import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import styled from 'styled-components';

import { CircularProgress } from '@mui/material';
import { request } from '../../functions/request';

import PageWrapper from '../../components/global/PageWrapper';

export default function Admin() {
  const [logs, setLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(true);

  useEffect(() => {
    request('/admin/logs')
      .then((response) => {
        setLogs(response);
        setLoadingLogs(false);
      })
      .catch(() => {
        toast.error('Error fetching logs');
      });
  }, []);

  return (
    <PageWrapper
      pageTitle="Administration Logs"
      sidebarType="admin"
      children={(
        <LogBox>
          {loadingLogs && <CircularProgress />}
          {logs ?
            logs.map((log) => (
              <LogEntry key={log._id}>
                [{log.timestamp.replace('T', ' ').replace('Z', '').split('.')[0]}] {log.text}
              </LogEntry>
            )) :
            ''}
        </LogBox>
      )}
    />
  );
}

const LogBox = styled.div`
  width: 100%;

  max-height: 500px;
  overflow-y: scroll;
`;

const LogEntry = styled.p`
  margin: 0;
  padding: 0;
`;
