import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@mui/material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import AddressTile from '../../components/customer/AddressTile';
import PageWrapper from '../../components/global/PageWrapper';
import useAddresses from '../../hooks/customer/useAddressData';

import { request } from '../../functions/request';

export default function Addresses() {
  const {
    claimedAddresses,
    unclaimedAddresses,
    loadingClaimedAddresses,
    loadingUnclaimedAddresses,
    refetchClaimedAddresses,
    refetchUnclaimedAddresses,
  } = useAddresses();

  const navigate = useNavigate();

  // if unclaimed address tile is clicked, add it to the claimed addresses
  const handleClaimAddress = (address) => {
    request(`/customer/address/${address.id}/claim`, { method: 'POST' })
      .then((data) => {
        if (!data) {
          toast.error('Something went wrong');
          return;
        }

        refetchClaimedAddresses();
        refetchUnclaimedAddresses();

        toast.info('Address claimed.');
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again later.');
      });
  };

  return (
    <PageWrapper
      pageTitle="Addresses"
      sidebarType="customer"
      children={(
        <PageWidgets>
          <AddressWidgetSection>
            <h2>Unclaimed Addresses</h2>
            <AddressGrid>
              {loadingUnclaimedAddresses && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}
              {!loadingUnclaimedAddresses &&
                unclaimedAddresses.map((address) => (
                  <AddressTile
                    key={address.id}
                    address={address}
                    onClick={() => handleClaimAddress(address)}
                  />
                ))}
            </AddressGrid>
          </AddressWidgetSection>
          <AddressWidgetSection>
            <h2>Claimed Addresses</h2>
            <AddressGrid>
              {loadingClaimedAddresses && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}
              {!loadingClaimedAddresses &&
                claimedAddresses.map((address) => (
                  <AddressTile
                    key={address.id}
                    address={address}
                    claimed
                    onClick={() => navigate(`/address/${address.id}`)}
                  />
                ))}
              {!loadingClaimedAddresses && claimedAddresses.length === 0 && (
                <p>
                  You have not claimed any addresses yet.
                  <br />
                  Click the + icon to add an address to your claimed list.
                </p>
              )}
            </AddressGrid>
          </AddressWidgetSection>
        </PageWidgets>
      )}
    />
  );
}

const PageWidgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap-reverse;
`;

const AddressWidgetSection = styled.div`
  flex: 1;
  width: 50%;
`;

const AddressGrid = styled.div`
  min-width: 280px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  max-height: none;
  overflow-y: none;
`;
