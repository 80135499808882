import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
} from '@mui/material';

import { toast } from 'react-toastify';

import styled from 'styled-components';
import {
  useSendToPlatformMutation,
  useInventoryDetails,
} from '../../hooks/customer/useInventoryData';

export default function SendToPlatformPopup({ open, selectedItems, setOpen }) {
  const [sendToPlatformData, setSendToPlatformData] = useState([]);

  const sendToPlatformMutation = useSendToPlatformMutation();
  const { refetch } = useInventoryDetails();

  const handleSendToPlatformDataChange = (e) => {
    setSendToPlatformData({
      ...sendToPlatformData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendToPlatformSubmit = () => {
    // Validate inputs
    if (!sendToPlatformData.files || !sendToPlatformData.platform) {
      toast.error('Please fill out all fields.');
      return;
    }

    const data = new FormData();
    const itemIds = selectedItems.map((item) => item._id);

    data.append('files', sendToPlatformData.files[0]);
    data.append('itemIds', itemIds);
    data.append('platform', sendToPlatformData.platform);
    data.append('notes', sendToPlatformData.notes || '');

    handleClose();

    sendToPlatformMutation.mutate(data, {
      onSuccess: () => {
        toast.success('Successfully raised request with reshipper.');
        refetch();
      },
      onError: () => {
        toast.error('Failed to raise request with reshipper.');
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Send Items to Platform</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the address of the intended recipient of the parcel as well as uploading a label if
          applicable.
        </DialogContentText>
        <InputFieldList>
          <TextField
            select
            label="Platform*"
            name="platform"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToPlatformData.platform ?? ''}
            onChange={handleSendToPlatformDataChange}
          >
            <MenuItem value="StockX">StockX</MenuItem>
            <MenuItem value="Alias">Alias</MenuItem>
            <MenuItem value="Klekt">Klekt</MenuItem>
            <MenuItem value="Laced">Laced</MenuItem>
            <MenuItem value="WeTheNew">WeTheNew</MenuItem>
            <MenuItem value="HypeBoost">HypeBoost</MenuItem>
            <MenuItem value="Sneakit">Sneakit</MenuItem>
            <MenuItem value="OnFeet">OnFeet</MenuItem>
          </TextField>
          <TextField
            label="Notes"
            name="notes"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToPlatformData.notes ?? ''}
            onChange={handleSendToPlatformDataChange}
          />
          <TextField
            label="Shipping Label*"
            name="files"
            fullWidth
            variant="outlined"
            size="small"
            type="file"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setSendToPlatformData({
                ...sendToPlatformData,
                files: e.target.files,
              });
            }}
            helperText="required, max 1 file, max 5MB"
          />
        </InputFieldList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSendToPlatformSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SendToPlatformPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOpen: PropTypes.func.isRequired,
};

const InputFieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
