import {
  Button, TextField,
  //  FormControlLabel, Checkbox,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageWrapper from '../../components/global/PageWrapper';
import { request } from '../../functions/request';
import AddressAcordian from '../../components/admin/AddressAcordian';

export default function UserProfile() {
  const params = useParams();

  const [user, setUser] = useState({});

  const [editState, setEditState] = useState(false);

  useEffect(() => {
    request(`/admin/user/${params.userId}`)
      .then((response) => {
        setUser(response);
      })
      .catch(() => {
        toast.error('Error fetching user details');
      });
  }, [params]);

  const handleChange = (e) => setUser({ [e.target.name]: e.target.value });

  const handleEditButton = () => {
    if (editState) {
      request(`/admin/user/${params.userId}`, {
        method: 'POST',
        body: user,
      })
        .then(() => {
          toast.success('User updated successfully');
        })
        .catch(() => {
          toast.error('Error updating user');
        });
    }

    setEditState(!editState);
  };

  const handleMakeReshipperButton = () => {
    request(`/admin/user/${params.userId}/makeReshipper`, {
      method: 'POST',
    })
      .then(() => {
        toast.success('User converted to a reshipper successfully');
      })
      .catch(() => {
        toast.error('Error updating user');
      });
  };

  const handleGrantSiteAccessButton = () => {
    request(`/admin/user/${params.userId}/grantAccess`, {
      method: 'POST',
    })
      .then(() => {
        toast.success('User granted access successfully');
      })
      .catch(() => {
        toast.error('Error granting user access to the site');
      });
  };

  const handleDisalowSiteAccessButton = () => {
    request(`/admin/user/${params.userId}/denyAccess`, {
      method: 'POST',
    })
      .then(() => {
        toast.success('User denied access successfully');
      })
      .catch(() => {
        toast.error('Error deny user access to the site.');
      });
  };

  return (
    <PageWrapper
      pageTitle="User Administration"
      sidebarType="admin"
      children={(
        <SplitPageWidgetContainer>
          <SplitPageWidget>
            <h2>Details</h2>
            <DiscordTile>
              <ProfilePicture src={user?.profilePicture} />
              <DiscordNameContainer>
                <DiscordName>{user?.username}</DiscordName>
                <DiscordId>{user?._id}</DiscordId>
              </DiscordNameContainer>
            </DiscordTile>
            <UserDetailsContainer>
              <TextField
                id="email"
                name="email"
                label="Email"
                value={user?.email || ''}
                disabled
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                id="phone"
                name="phone"
                label="Phone"
                value={user?.phone || ''}
                disabled
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                id="stripeCustomer"
                name="stripeCustomer"
                label="Stripe Customer Id"
                value={user?.stripeCustomer || ''}
                disabled
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                id="accountStatus"
                name="accountStatus"
                label="Account Status"
                value={user?.accountStatus || ''}
                disabled
                fullWidth
                size="small"
                variant="outlined"
              />
              {user?.homeAddress && (
                <AddressAcordian
                  address={user?.homeAddress || {}}
                  isReshipper={false}
                  name="Home Address"
                />
              )}
            </UserDetailsContainer>
            {/* <h3>Notifications</h3>
            <FormControlLabel
              control={<Checkbox disabled checked={user?.allowEmailNotifications} />}
              label="Email"
            />
            <FormControlLabel
              control={<Checkbox disabled checked={user?.allowSMSNotifications} />}
              label="SMS"
            />
            <FormControlLabel
              control={<Checkbox disabled checked={user?.allowDiscordNotifications} />}
              label="Discord"
            /> */}
          </SplitPageWidget>
          <SplitPageWidget>
            <h2>Actions</h2>
            <PageActions>
              {!user?.roles?.includes('reshipper') && (
                <Button variant="contained" color="primary" onClick={handleMakeReshipperButton}>
                  Make Reshipper
                </Button>
              )}
              {!user?.access && (
                <Button variant="contained" color="primary" onClick={handleGrantSiteAccessButton}>
                  Grant Site Access
                </Button>
              )}
              {user?.access && (
                <Button variant="contained" color="primary" onClick={handleDisalowSiteAccessButton}>
                  Disallow Site Access
                </Button>
              )}

              <Button variant="contained" color="primary" onClick={() => handleEditButton()}>
                {editState ? 'Save' : 'Edit'} Details
              </Button>
            </PageActions>

            <InputFields>
              <h2>Account Information</h2>
              <TextField
                id="id"
                name="id"
                label="ID"
                value={user?._id || ''}
                disabled
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                id="lastLoggedIn"
                name="lastLoggedIn"
                label="Last Logged In"
                size="small"
                value={user?.lastLoggedIn || ''}
                disabled
                fullWidth
                variant="outlined"
              />
              <TextField
                id="created"
                name="created"
                label="Created"
                size="small"
                value={user?.created || ''}
                disabled
                fullWidth
                variant="outlined"
              />
              <TextField
                id="lastEdited"
                name="lastEdited"
                label="Last Edited"
                size="small"
                value={user?.lastEdited || ''}
                disabled
                fullWidth
                variant="outlined"
              />
              <TextField
                id="access"
                name="access"
                label="Access"
                size="small"
                value={user?.access || ''}
                disabled
                fullWidth
                variant="outlined"
              />

              <TextField
                id="roles"
                name="roles"
                label="Roles"
                value={user?.roles || ''}
                disabled={!editState}
                onChange={handleChange}
                fullWidth
                size="small"
                variant="outlined"
              />
            </InputFields>
          </SplitPageWidget>
        </SplitPageWidgetContainer>
      )}
    />
  );
}

const UserDetailsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DiscordTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProfilePicture = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
`;

const DiscordNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const DiscordName = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const DiscordId = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const SplitPageWidgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
`;

const SplitPageWidget = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 0;
  }
`;

const PageActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  margin-top: 20px;
`;
