import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Chip } from '@mui/material';
import { Close } from '@mui/icons-material';

export default function ItemTile({ item, setItemList, customer }) {
  return (
    <ParcelListItem>
      <ItemQuantity>({item?.quantity}x)</ItemQuantity>
      <ItemProperty>
        <ItemPropertyTitle>Item</ItemPropertyTitle>
        <ItemPropertyValue>{item?.name}</ItemPropertyValue>
      </ItemProperty>
      <ItemProperty id="hide">
        <ItemPropertyTitle>Sku</ItemPropertyTitle>
        <ItemPropertyValue>{item?.sku}</ItemPropertyValue>
      </ItemProperty>
      <ItemProperty>
        <ItemPropertyTitle>Customer</ItemPropertyTitle>
        <ItemPropertyValue>{customer}</ItemPropertyValue>
      </ItemProperty>
      <Chip id="hide" label={item?.condition} />
      <Close
        onClick={() => setItemList((prev) => prev.filter((i) => i !== item))}
        sx={{ cursor: 'pointer' }}
      />
    </ParcelListItem>
  );
}

ItemTile.propTypes = {
  item: PropTypes.shape({
    quantity: PropTypes.number,
    name: PropTypes.string,
    sku: PropTypes.string,
    condition: PropTypes.string,
  }).isRequired,
  setItemList: PropTypes.func.isRequired,
  customer: PropTypes.string.isRequired,
};

const ItemQuantity = styled('div')`
  font-weight: 600;
`;

const ItemProperty = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ItemPropertyTitle = styled('div')`
  font-weight: 600;
`;

const ItemPropertyValue = styled('div')``;

const ParcelListItem = styled('div')`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
  gap: 10px;

  @media (max-width: 1200px) {
    #hide {
      display: none;
    }
  }
`;
