import { useQuery } from '@tanstack/react-query';
import { request } from '../../functions/request';

const fetchClaimedAddresses = async () => request('/customer/address/claimed/');

const fetchUnclaimedAddresses = async () => request('/customer/address/unclaimed');

export default function useAddresses() {
  const {
    data: claimedAddresses,
    isLoading: loadingClaimedAddresses,
    refetch: refetchClaimedAddresses,
  } = useQuery(['claimedAddresses'], fetchClaimedAddresses);

  const {
    data: unclaimedAddresses,
    isLoading: loadingUnclaimedAddresses,
    refetch: refetchUnclaimedAddresses,
  } = useQuery(['unclaimedAddresses'], fetchUnclaimedAddresses);

  return {
    claimedAddresses,
    unclaimedAddresses,
    loadingClaimedAddresses,
    loadingUnclaimedAddresses,
    refetchClaimedAddresses,
    refetchUnclaimedAddresses,
  };
}
