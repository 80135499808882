import React from 'react';
import { Chip } from '@mui/material';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import countryData from '../../data/countries';

export default function ReshipperTile({ reshipper }) {
  const navigate = useNavigate();

  const countryCodes = [];

  if (reshipper.reshippingAddresses) {
    reshipper.reshippingAddresses.forEach((address) => {
      const country = countryData.find((e) => e.countryName === address.country);
      if (country) countryCodes.push(country.countryShortcode);
      else {
        countryCodes.push('NP');
      }
    });
  }

  return (
    <ReshipperTileContainer onClick={() => navigate(`/admin/reshippers/${reshipper._id}`)}>
      <ReshipperTileHeader>
        <ProfilePicture src={reshipper?.profilePicture} />
        {reshipper?.username || ''}
      </ReshipperTileHeader>
      <ReshipperTileFlag>
        {reshipper.reshippingStatus.status !== 'AVAILABLE' && (
          <Chip className="statusChip" label={reshipper.reshippingStatus.status} />
        )}
        {reshipper.stripeConnectId && <Chip className="statusChip" label="Stripe" />}
        {reshipper.stripeIdentityId && <Chip className="statusChip" label="Verified" />}
        {countryCodes.map((countryCode) => (
          <ReactCountryFlag
            key={countryCode}
            countryCode={countryCode}
            svg
            style={{
              height: '35px',
              width: '35px',
              marginLeft: '10px',
            }}
          />
        ))}
      </ReshipperTileFlag>
    </ReshipperTileContainer>
  );
}

ReshipperTile.propTypes = {
  reshipper: PropTypes.shape({
    _id: PropTypes.string,
    profilePicture: PropTypes.string,
    username: PropTypes.string,
    reshippingStatus: PropTypes.shape({
      status: PropTypes.string,
    }),
    stripeConnectId: PropTypes.string,
    stripeIdentityId: PropTypes.string,
    reshippingAddresses: PropTypes.arrayOf(
      PropTypes.shape({
        country: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const ReshipperTileContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.8);
  }
`;

const ReshipperTileHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const ReshipperTileFlag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  @media (max-width: 512px) {
    display: none;
  }
`;

const ProfilePicture = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
`;
