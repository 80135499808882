import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';

import styled from 'styled-components';

export default function ViewActionPopup({ open, setOpen }) {
  const [editMode, setEditMode] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateAction = () => {
    setEditMode(true);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Action Details</DialogTitle>
      <DialogContent>
        <InputFieldList />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdateAction} variant="contained">
          {editMode ? 'Save' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ViewActionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const InputFieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
