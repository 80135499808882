import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

export default function UserTile({ user }) {
  const navigate = useNavigate();

  return (
    <UserTileContainer onClick={() => navigate(`/admin/users/${user._id}`)}>
      <UserTileHeader>
        <ProfilePicture src={user?.profilePicture} />
        {user?.username || ''}
      </UserTileHeader>
      <UserTileFlag>
        {user?.roles?.includes('admin') && <Chip className="statusChip" label="Admin" />}
        {user?.roles?.includes('reshipper') && <Chip className="statusChip" label="Reshipper" />}
        {user?.access && <Chip className="statusChip" label="Access" />}
      </UserTileFlag>
    </UserTileContainer>
  );
}

UserTile.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    profilePicture: PropTypes.string,
    username: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    access: PropTypes.bool,
  }).isRequired,
};

const UserTileContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.8);
  }
`;

const UserTileHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const UserTileFlag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  @media (max-width: 512px) {
    display: none;
  }
`;

const ProfilePicture = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
`;
