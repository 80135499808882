import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

export default function FeaturedInfo({ expectedParcels }) {
  const numberOfParcels = expectedParcels?.reduce(
    (acc, delivery) => acc + delivery.parcels.length,
    0,
  );

  return (
    <Featured>
      {/* <FeaturedItem>
        <FeaturedTitle>Parcels Reshipped</FeaturedTitle>
        <FeaturedContainer>
          <FeaturedValue>0</FeaturedValue>
        </FeaturedContainer>
      </FeaturedItem> */}
      <FeaturedItem>
        <Link to="/inventory" className="text-link">
          <FeaturedTitle>Current Inventory</FeaturedTitle>
          <FeaturedContainer>
            <FeaturedValue>{numberOfParcels || 0}</FeaturedValue>
          </FeaturedContainer>
        </Link>
      </FeaturedItem>
      <FeaturedItem>
        <FeaturedTitle>Pending Parcels</FeaturedTitle>
        <FeaturedContainer>
          <FeaturedValue>{numberOfParcels || 0}</FeaturedValue>
        </FeaturedContainer>
      </FeaturedItem>
      {/* <Link to="/inventory" className="text-link">
        <FeaturedItem>
          <FeaturedTitle>Earned</FeaturedTitle>
          <FeaturedContainer>
            <FeaturedValue>£0.00</FeaturedValue>
          </FeaturedContainer>
        </FeaturedItem>
      </Link> */}
    </Featured>
  );
}

FeaturedInfo.propTypes = {
  expectedParcels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      customerName: PropTypes.string,
      parcels: PropTypes.arrayOf(
        PropTypes.shape({
          quantity: PropTypes.number,
          dateExpected: PropTypes.string,
        }),
      ),
    }),
  ),
};

FeaturedInfo.defaultProps = {
  expectedParcels: [],
};

const Featured = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px
`;

const FeaturedItem = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  width: 100%;
  max-width: 250px;
  border: 1px solid rgba(224, 224, 224, 1);
`;

const FeaturedTitle = styled.div`
  font-size: 20px;
`;

const FeaturedContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
`;

const FeaturedValue = styled.div`
  font-size: 30px;
  font-weight: 600;
`;
