/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function FeaturedInfo({ expectedParcels, inventory }) {
  const numberOfParcels = useMemo(
    () => expectedParcels?.reduce(
      (total, delivery) => total +
          delivery.parcels.reduce((parcelTotal, parcel) => parcelTotal + parcel.quantity, 0),
      0,
    ) || 0,
    [expectedParcels],
  );

  return (
    <Featured>
      <FeaturedItem onClick={() => window.open('/inventory', '_self')} className="clickable">
        <FeaturedTitle>Current Inventory</FeaturedTitle>
        <FeaturedContainer>
          <FeaturedValue>{inventory?.length}</FeaturedValue>
        </FeaturedContainer>
      </FeaturedItem>

      <FeaturedItem>
        <FeaturedTitle>Pending Parcels</FeaturedTitle>
        <FeaturedContainer>
          <FeaturedValue>{numberOfParcels}</FeaturedValue>
        </FeaturedContainer>
      </FeaturedItem>

      <FeaturedItem onClick={() => window.open('/payments', '_self')} className="clickable">
        <FeaturedTitle>Outstanding Fees</FeaturedTitle>
        <FeaturedContainer>
          <FeaturedValue>£0.00</FeaturedValue>
        </FeaturedContainer>
      </FeaturedItem>
    </Featured>
  );
}

FeaturedInfo.propTypes = {
  expectedParcels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      parcels: PropTypes.arrayOf(
        PropTypes.shape({
          quantity: PropTypes.number,
        }),
      ),
    }),
  ),
};

FeaturedInfo.defaultProps = {
  expectedParcels: [],
};

const Featured = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FeaturedItem = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-bottom: 10px;
  max-width: 250px;
  width: 100%;
  border: 1px solid rgba(224, 224, 224, 1);

  &.clickable {
    cursor: pointer;
  }

  @media (max-width: 575px) {
    width: 40%;
    padding: 10px;
    margin: 3px;
  }
`;

const FeaturedTitle = styled.div`
  font-size: 20px;

  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

const FeaturedContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;

  @media (max-width: 575px) {
    margin: 5px 0px;
  }
`;

const FeaturedValue = styled.div`
  font-size: 30px;
  font-weight: 600;

  @media (max-width: 575px) {
    font-size: 24px;
  }
`;
