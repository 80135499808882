import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Menu, MenuItem } from '@mui/material';
import { KeyboardArrowDown, Delete } from '@mui/icons-material';
import styled from 'styled-components';
import {
  useMarkAsArchivedMutation,
  useMarkAsSentMutation,
} from '../../hooks/reshipper/useInventoryData';
import { request } from '../../functions/request';

function ParcelActionMenu({ selectedItems }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const markAsArchivedMutation = useMarkAsArchivedMutation();
  const markAsSentMutation = useMarkAsSentMutation();

  const handleParcelActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArchiveItem = () => {
    markAsArchivedMutation.mutate(
      { items: selectedItems.map((item) => item._id) },
      {
        onSuccess: () => {
          toast.success('Successfully archived items.');
        },
        onError: (err) => {
          toast.error(err.message);
        },
      },
    );
  };

  const handleContactCustomer = () => {
    request(`/reshipper/discord/${selectedItems[0].customerId}/discordChat`)
      .then((data) => {
        window.open(`https://discord.com/channels/1026169667447963738/${data.channelId}`, '_blank');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleMarkAsSent = () => {
    markAsSentMutation.mutate(
      { items: selectedItems.map((item) => item._id) },
      {
        onSuccess: () => {
          toast.success('Successfully archived items.');
        },
        onError: (err) => {
          toast.error(err.message);
        },
      },
    );
  };

  return (
    <div>
      <Button
        onClick={handleParcelActionsClick}
        variant="contained"
        disabled={selectedItems?.length === 0}
      >
        Parcel Actions
        <KeyboardArrowDown />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={handleContactCustomer}
          disabled={selectedItems?.some((item) => item.customerId !== selectedItems[0].customerId)}
        >
          Contact Customer
        </MenuItem>
        <MenuItem
          onClick={handleMarkAsSent}
        >
          Mark as Sent
        </MenuItem>
        <HorizontalLine />
        <MenuItem onClick={handleArchiveItem}>
          <Delete sx={{ marginRight: '10px' }} />
          Archive
        </MenuItem>
      </Menu>
    </div>
  );
}

ParcelActionMenu.propTypes = {
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      customerId: PropTypes.string,
      status: PropTypes.string,
    }),
  ).isRequired,
};

const HorizontalLine = styled.hr`
  border: 1px solid #ddd;
  margin: 0 10px;
`;

export default ParcelActionMenu;
