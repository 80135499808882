/* eslint-disable max-len */
import Grid from '@mui/material/Unstable_Grid2';

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Skeleton, TextField } from '@mui/material';
import UserTile from '../../components/admin/UserTile';
import { request } from '../../functions/request';
import PageWrapper from '../../components/global/PageWrapper';

export default function Reshippers() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request('/admin/getAllUsers').then((res) => {
      setLoading(false);
      setUsers(res);
      setFilteredUsers(res);
    });
  }, []);

  useEffect(() => {
    setFilteredUsers((prev) => prev.filter((user) => user.username.toLowerCase().includes(search.toLowerCase())));
  }, [search, users]);

  return (
    <PageWrapper
      pageTitle="User Administration"
      sidebarType="admin"
      children={(
        <>
          <TitleBar>
            <h2>View All Users</h2>

            <TextField
              sx={{ minWidth: '150px' }}
              label="Search"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="small"
              className="textField"
            />
          </TitleBar>
          <Grid container rowSpacing={0} columnSpacing={1} disableEqualOverflow>
            {loading ? (
              <LoadingSkeletons>
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
              </LoadingSkeletons>
            ) : (
              filteredUsers.map((user) => (
                <Grid xs={12} lg={6} key={user._id}>
                  <UserTile user={user} />
                </Grid>
              ))
            )}
          </Grid>
        </>
      )}
    />
  );
}

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

  h2 {
    margin: 0;
  }
`;

const LoadingSkeletons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  span {
    width: 49% !important;
    height: 50px !important;
  }

  @media (max-width: 1024px) {
    span {
      width: 100% !important;
    }
  }
`;
