import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { TextField, Button, CircularProgress } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styled from 'styled-components';
import DiscordLogoSrc from '../../images/DiscordLogoWhite.svg';

import { request } from '../../functions/request';

import PageWrapper from '../../components/global/PageWrapper';

import { useAuth } from '../../providers/AuthProvider';

export default function Address() {
  const { user } = useAuth();
  const { addressId } = useParams();

  const [address, setAddress] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request(`/customer/address/${addressId}`).then((data) => {
      // TODO
      const newData = { ...data };
      Object.entries(newData).forEach(([key, value]) => {
        if (typeof value === 'string' && value.includes('[IDENTIFIER]')) {
          newData[key] = value.replace('[IDENTIFIER]', user.username);
        }
      });

      setAddress(newData);
      setLoading(false);
    });
  }, [addressId, user.username]);

  const viewDiscordChat = () => {
    request(`/customer/discord/${address.reshipperId}/discordChat`)
      .then((data) => {
        window.open(`https://discord.com/channels/1026169667447963738/${data.channelId}`, '_blank');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const removeFromClaimed = () => {
    // post to api/customer/removeFromClaimed
    request(`/customer/address/${addressId}/unclaim`, { method: 'POST' })
      .then(() => {
        toast.success('Removed from claimed addresses');
        window.location.assign('/addresses');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const copyToClipboard = () => {
    const addressFormatted = `${address.firstName} ${address.lastName}\n${address.line1}\n${
      address?.line2 || ''
    }\n${address.postcode}\n${address.state}\n${address.city}\n${address.country}`;
    navigator.clipboard.writeText(addressFormatted);
    toast.success('Copied to clipboard');
  };

  const exportAYCD = () => {
    // create aycd format object
    const aycdString = [
      {
        name: `${address.firstName} ${address.lastName}`,
        size: '',
        profileGroup: '',
        billingAddress: {
          name: `${address.firstName} ${address.lastName}`,
          email: '',
          phone: `${address.phonePrefix} ${address.phone}`,
          line1: address.line1,
          line2: address.line2,
          line3: '',
          postCode: address.postcode,
          city: address.city,
          country: address.country,
          state: address.state,
        },
        shippingAddress: {
          name: `${address.firstName} ${address.lastName}`,
          email: '',
          phone: `${address.phonePrefix} ${address.phone}`,
          line1: address.line1,
          line2: address.line2,
          line3: '',
          postCode: address.postcode,
          city: address.city,
          country: address.country,
          state: address.state,
        },
        paymentDetails: {
          nameOnCard: `${address.firstName} ${address.lastName}`,
          cardType: '',
          cardNumber: '',
          cardExpMonth: '',
          cardExpYear: '',
          cardCvv: '',
        },
        sameBillingAndShippingAddress: true,
        onlyCheckoutOnce: false,
        matchNameOnCardAndAddress: true,
      },
    ];

    // convert to a string and then download as a file
    const aycdBlob = new Blob([JSON.stringify(aycdString)], {
      type: 'application/json',
    });
    const aycdUrl = URL.createObjectURL(aycdBlob);
    const aycdLink = document.createElement('a');
    aycdLink.href = aycdUrl;
    aycdLink.download = `${address.country}_aycd.json`;
    document.body.appendChild(aycdLink);
    aycdLink.click();
    document.body.removeChild(aycdLink);
  };

  return (
    <PageWrapper
      pageTitle={loading ? '' : `${address?.reshipper}'s ${address?.country} Address`}
      sidebarType="customer"
      children={
        loading ? (
          <Centered>
            <CircularProgress />
          </Centered>
        ) : (
          <>
            {address?.reshippingStatus && address?.reshippingStatus?.status !== 'AVAILABLE' && (
              <AddressStatusTile>
                <AddressStatusTileIcon>
                  <WarningAmberIcon fontSize="large" />
                </AddressStatusTileIcon>
                <div>
                  <h3>Address Information</h3>
                  <p>
                    {`This reshipper will be away between ${new Date(
                      address?.reshippingStatus?.awayFrom,
                    ).toLocaleDateString()} and ${new Date(
                      address?.reshippingStatus?.awayUntil,
                    ).toLocaleDateString()}.`}
                    <b>
                      {` They will  ${
                        address?.reshippingStatus?.canReceive ? '' : 'not '
                      } be able to receive parcels during this time.`}
                    </b>
                  </p>
                </div>
              </AddressStatusTile>
            )}
            <PageWidgets>
              <AddressSection>
                <h2>Details</h2>
                <AddressDetailBoxes>
                  <InlineInput>
                    <TextField
                      name="firstName"
                      label="First Name"
                      value={address?.firstName || ''}
                      fullWidth
                      variant="outlined"
                      disabled
                      size="small"
                    />
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={address?.lastName || ''}
                      fullWidth
                      variant="outlined"
                      disabled
                      size="small"
                    />
                  </InlineInput>
                  <TextField
                    name="line1"
                    label="Address Line 1"
                    value={address?.line1 || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <TextField
                    name="line2"
                    label="Address Line 2"
                    value={address?.line2 || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <TextField
                    name="city"
                    label="City"
                    value={address?.city || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <TextField
                    name="postcode"
                    label="Postcode"
                    value={address?.postcode || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <TextField
                    name="country"
                    label="Country"
                    value={address?.country || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <TextField
                    name="state"
                    label="State"
                    value={address?.state || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <InlineInput>
                    <TextField
                      name="phonePrefix"
                      label="Phone Prefix"
                      value={address?.phonePrefix || ''}
                      variant="outlined"
                      disabled
                      size="small"
                    />
                    <TextField
                      name="phone"
                      label="Phone Number"
                      value={address?.phone || ''}
                      variant="outlined"
                      fullWidth
                      disabled
                      size="small"
                    />
                  </InlineInput>
                  <TextField
                    name="bannedSites"
                    label="Banned Sites"
                    value={address?.bannedSites || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                  <TextField
                    name="notes"
                    label="Notes"
                    value={address?.notes || ''}
                    variant="outlined"
                    disabled
                    size="small"
                  />
                </AddressDetailBoxes>
              </AddressSection>
              <AddressSection>
                <h2>Actions</h2>
                <AddressActionButtons>
                  <Button variant="contained" color="primary" onClick={viewDiscordChat}>
                    <SpaceApart>
                      View Chat in
                      <DiscordLogo src={DiscordLogoSrc} />
                    </SpaceApart>
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => exportAYCD()}>
                    Export to AYCD
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      copyToClipboard();
                    }}
                  >
                    Copy to Clipboard
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => removeFromClaimed()}>
                    Removed From Claimed
                  </Button>
                  <Button variant="contained" color="error" disabled>
                    Report Issue
                  </Button>
                </AddressActionButtons>
              </AddressSection>
            </PageWidgets>
          </>
        )
      }
    />
  );
}

const SpaceApart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const DiscordLogo = styled.img`
  height: 25px;
`;

const AddressStatusTile = styled.div`
  background-color: #fbeeac;
  border-radius: 5px;
  display: flex;
  width: fit-content;
  padding: 10px;
  margin-bottom: 10px;

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }
`;

const PageWidgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap-reverse;
`;

const AddressStatusTileIcon = styled.div`
  margin: auto 30px auto 10px;
  vertical-align: middle;
`;

const AddressSection = styled.div`
  flex: 1;
  width: 100%;
  min-width: 250px;
`;

const AddressDetailBoxes = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const InlineInput = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;

const AddressActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
