import {
  Button, MenuItem, Skeleton, TextField,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import countryData from '../../data/countries';
import AddressAcordian from '../../components/admin/AddressAcordian';
import PageWrapper from '../../components/global/PageWrapper';
import { request } from '../../functions/request';

export default function AdminReshipper() {
  const params = useParams();
  const [selectedCountryToAllow, setSelectedCountryToAllow] = useState('');
  const [countries, setCountries] = useState([]);
  const [enableSave, setEnableSave] = useState(false);
  const [chats, setChats] = useState([]);
  const [loadingChats, setLoadingChats] = useState(true);
  const [loadingAddresses, setLoadingAddresses] = useState(true);

  const [reshipper, setReshipper] = useState({});

  useEffect(() => {
    setCountries(countryData.map((country) => country.countryName));
  }, []);

  const handleChange = (e) => {
    setReshipper({ ...reshipper, [e.target.name]: e.target.value });
    setEnableSave(true);
  };

  const handleEditButton = () => {
    request(`/admin/user/${params.reshipperId}`, {
      method: 'POST',
      body: {
        stripeConnectId: reshipper.stripeConnectId,
        stripeIdentityId: reshipper.stripeIdentityId,
      },
    })
      .then(() => {
        toast.success('Reshipper updated successfully');
        setEnableSave(false);
      })
      .catch(() => {
        toast.error('Error updating reshipper');
      });
  };

  const handleAllowCountryButton = () => {
    if (selectedCountryToAllow === '') {
      toast.error('Please select a country to allow');
      return;
    }

    request(`/admin/reshipper/${params.reshipperId}/allowCountry`, {
      method: 'POST',
      body: {
        country: selectedCountryToAllow,
      },
    })
      .then((response) => {
        toast.success('Country allowed successfully');
        setReshipper(response);
      })
      .catch(() => {
        toast.error('Error allowing country');
      });
  };

  useEffect(() => {
    request(`/admin/reshipper/${params.reshipperId}/getAllChats`)
      .then((response) => {
        setChats(response);
        setLoadingChats(false);
      })
      .catch(() => {
        toast.error('Error fetching chats');
      });
  }, [params]);

  useEffect(() => {
    request(`/admin/user/${params.reshipperId}`)
      .then((response) => {
        if (!response) {
          toast.error('User not found');
          return;
        }
        if (!response.roles.includes('reshipper')) {
          toast.error('User is not a reshipper');
          return;
        }
        setReshipper(response);
        setLoadingAddresses(false);
      })
      .catch(() => {
        toast.error('Error fetching reshipper details');
      });
  }, [params]);

  const removeReshipperRole = () => {
    request(`/admin/reshipper/${params.reshipperId}/removeReshipperRole`, {
      method: 'POST',
    });
  };

  const viewStripeConnectAccount = () => {
    window.open(
      `https://dashboard.stripe.com/connect/accounts/${reshipper.stripeConnectId}`,
      '_blank',
    );
  };

  const viewStripeIdentity = () => {
    window.open(
      `https://dashboard.stripe.com/identity/verification-sessions/${reshipper.stripeIdentityId}`,
      '_blank',
    );
  };

  return (
    <PageWrapper
      pageTitle={`${reshipper?.username}'s Reshipper Profile`}
      sidebarType="admin"
      children={(
        <SplitPageWidgetContainer>
          <SplitPageWidget>
            <h2>Reshipping Addresses</h2>
            {loadingAddresses ? <Skeleton variant="rectangular" height={70} /> : null}
            {reshipper?.reshippingAddresses?.length === 0 && (
              <p>This reshipper does not have any reshipping addresses set.</p>
            )}
            {reshipper?.reshippingAddresses?.map((address) => (
              <AddressAcordian
                key={address._id}
                address={address}
                editable
                setReshipper={setReshipper}
              />
            ))}
            <AddCountrySection>
              <TextField
                label="Country"
                name="country"
                select
                size="small"
                sx={{
                  minWidth: '250px',
                  backgroundColor: 'white',
                }}
                value={selectedCountryToAllow}
                onChange={(e) => setSelectedCountryToAllow(e.target.value)}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant="outlined" onClick={() => handleAllowCountryButton()}>
                Allow Country
              </Button>
            </AddCountrySection>
            <h2>Chats</h2>

            <ChatsTiles>
              {loadingChats && (
                <>
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                  <Skeleton variant="rectangular" height={50} />
                </>
              )}
              {chats.map((chat) => (
                <ChatCard
                  onClick={() => window.open(
                    `https://discord.com/channels/1026169667447963738/${chat.channelId}`,
                    '_blank',
                  )}
                >
                  <ChatCardTitle>{chat.customerName}</ChatCardTitle>
                  <ChatCardDate>{chat.created}</ChatCardDate>
                </ChatCard>
              ))}
            </ChatsTiles>
          </SplitPageWidget>
          <SplitPageWidget>
            <h2>Reshipper Actions</h2>
            <PageActions>
              {reshipper?.stripeConnectId && (
                <Button variant="contained" onClick={viewStripeConnectAccount}>
                  View Stripe Account
                </Button>
              )}
              {reshipper?.stripeIdentityId && (
                <Button variant="contained" onClick={viewStripeIdentity}>
                  View Stripe Identity
                </Button>
              )}

              <Button variant="contained" onClick={() => removeReshipperRole()}>
                Remove Reshipper Role
              </Button>

              <Button variant="contained" onClick={() => handleEditButton()} disabled={!enableSave}>
                Save Changes
              </Button>
            </PageActions>

            <InputFields>
              <h2>Reshipper Details</h2>
              <TextField
                name="id"
                label="Discord ID"
                value={reshipper?._id || ''}
                fullWidth
                disabled
                size="small"
                variant="outlined"
              />
              <TextField
                name="lastLoggedIn"
                label="Last Logged In"
                disabled
                size="small"
                value={reshipper?.lastLoggedIn || ''}
                fullWidth
                variant="outlined"
              />

              <TextField
                name="roles"
                label="Roles"
                disabled
                value={reshipper?.roles || ''}
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                name="status"
                label="status"
                disabled
                value={reshipper?.reshippingStatus?.status || ''}
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                name="stripeConnectId"
                label="Stripe Connect ID"
                value={reshipper?.stripeConnectId || ''}
                onChange={handleChange}
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                name="stripeIdentityId"
                label="Stripe Identity"
                value={reshipper?.stripeIdentityId || ''}
                onChange={handleChange}
                fullWidth
                size="small"
                variant="outlined"
              />
              <TextField
                name="email"
                label="Email"
                value={reshipper?.email || ''}
                onChange={handleChange}
                fullWidth
                size="small"
                variant="outlined"
              />
            </InputFields>
          </SplitPageWidget>
        </SplitPageWidgetContainer>
      )}
    />
  );
}

const ChatCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background-color: white;
  border-radius: 5px;

  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.8);
  }
`;

const ChatCardTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const ChatCardDate = styled.div`
  font-size: 14px;
`;

const ChatsTiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SplitPageWidgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
`;

const SplitPageWidget = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    margin-bottom: 10px;
  }
`;

const PageActions = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  margin-top: 20px;
`;

const AddCountrySection = styled.div`
  margin: 10px 0px 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;
