import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import { toast } from 'react-toastify';

import styled from 'styled-components';
import {
  useInventoryDetails,
  useSendToAddressMutation,
} from '../../hooks/customer/useInventoryData';

export default function SendToAddressPopup({ open, selectedItems, setOpen }) {
  const [sendToAddressData, setSendToAddressData] = useState([]);

  const sendToAddressMutation = useSendToAddressMutation();
  const { refetch } = useInventoryDetails();

  const handleSendToAddressDataChange = (e) => {
    setSendToAddressData({
      ...sendToAddressData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendToAddressSubmit = () => {
    // Validate inputs
    if (
      !sendToAddressData.name ||
      !sendToAddressData.line1 ||
      !sendToAddressData.postcode ||
      !sendToAddressData.city ||
      !sendToAddressData.country
    ) {
      toast.error('Please fill out all fields.');
      return;
    }

    const data = new FormData();

    if (sendToAddressData.files) {
      sendToAddressData.files.forEach((file) => {
        data.append('files', file);
      });
    }

    const itemIds = [];
    selectedItems.forEach((item) => {
      itemIds.push(item._id);
    });

    data.append('itemIds', itemIds);

    data.append('name', sendToAddressData.name);
    data.append('line1', sendToAddressData.line1);
    data.append('line2', sendToAddressData.line2);
    data.append('postcode', sendToAddressData.postcode);
    data.append('city', sendToAddressData.city);
    data.append('country', sendToAddressData.country);

    toast.info('Sending request to reshipper...');

    handleClose();

    // Replace the requestFormBody with the custom mutation
    sendToAddressMutation.mutate(data, {
      onSuccess: () => {
        toast.success('Successfully raised request with reshipper.');
        refetch();
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Send Items to address</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the address of the intended recipient of the parcel as well as uploading a label if
          applicable.
        </DialogContentText>
        <InputFieldList>
          <TextField
            label="Name"
            name="name"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToAddressData.name ?? ''}
            onChange={handleSendToAddressDataChange}
          />
          <TextField
            label="Address Line 1"
            name="line1"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToAddressData.line1 ?? ''}
            onChange={handleSendToAddressDataChange}
          />
          <TextField
            label="Address Line 2"
            name="line2"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToAddressData.line2 ?? ''}
            onChange={handleSendToAddressDataChange}
          />
          <TextField
            label="City"
            name="city"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToAddressData.city ?? ''}
            onChange={handleSendToAddressDataChange}
          />
          <TextField
            label="Postcode"
            name="postcode"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToAddressData.postcode ?? ''}
            onChange={handleSendToAddressDataChange}
          />
          <TextField
            label="Country"
            name="country"
            fullWidth
            variant="outlined"
            size="small"
            value={sendToAddressData.country ?? ''}
            onChange={handleSendToAddressDataChange}
          />
          <TextField
            label="Shipping Label"
            name="files"
            fullWidth
            variant="outlined"
            size="small"
            type="file"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              multiple: true,
            }}
            onChange={(e) => {
              setSendToAddressData({
                ...sendToAddressData,
                files: e.target.files,
              });
            }}
            helperText="max 3 files, 5MB each"
          />
        </InputFieldList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSendToAddressSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SendToAddressPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOpen: PropTypes.func.isRequired,
};

const InputFieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
