import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '../../functions/request';

// Fetch customer account details
const fetchAccountDetails = async () => {
  const data = await request('/reshipper/payments/pending');
  return data;
};

const fetchPaymentHistoryQuery = async () => request('/reshipper/payments/history');

const voidMutation = async (payment) => request('/reshipper/payments/void/', {
  method: 'POST',
  body: { paymentId: payment._id },
});

const archiveMutation = async (data) => request('/reshipper/payments/archive', {
  method: 'POST',
  body: data,
});

export function usePendingPaymentsData() {
  return useQuery(['pendingReshipperPayments'], fetchAccountDetails);
}

export function usePaymentHistoryData() {
  return useQuery(['paymentReshipperHistory'], fetchPaymentHistoryQuery);
}

export const useArchiveMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: archiveMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['todos'] });
    },
  });
};

export const useVoidMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: voidMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['todos'] });
    },
  });
};
