import styled from 'styled-components';
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  TextField, Button, MenuItem,
  // FormControlLabel, Checkbox,
} from '@mui/material';

import { useAccountDetails, useUpdateAccountDetails } from '../../hooks/customer/useAccountData';
import countryData from '../../data/countries';
import PageWrapper from '../../components/global/PageWrapper';

export default function Account() {
  const [accountDetails, setAccountDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState({});

  const countries = countryData.map((country) => country.countryName);

  const { data: accountData, refetch } = useAccountDetails();

  useEffect(() => {
    setAccountDetails(accountData);
    setAddressDetails(accountData?.homeAddress);
  }, [accountData]);

  const updateMutation = useUpdateAccountDetails();

  const saveAccountDetails = () => {
    updateMutation.mutate(
      {
        ...accountDetails,
        homeAddress: addressDetails,
      },
      {
        onSuccess: () => {
          toast.success('Account details updated successfully');
          refetch();
        },
        onError: () => {
          toast.error('Error updating account details');
        },
      },
    );
  };

  const changeAddressDetails = (e) => {
    setAddressDetails({
      ...addressDetails,
      [e.target.name]: e.target.value,
    });
  };

  const changeAccountDetails = (e) => {
    setAccountDetails({
      ...accountDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <PageWrapper
      pageTitle="Account Settings"
      sidebarType="customer"
      titleChildren={(
        <Button
          variant="contained"
          sx={{ maxHeight: '36px', margin: 'auto 0 auto auto' }}
          onClick={saveAccountDetails}
        >
          Save Changes
        </Button>
      )}
    >
      <AccountSection>
        <h2>Your Details</h2>
        <AccountTextFields>
          <InlineInput>
            <TextField
              name="firstName"
              label="First Name"
              fullWidth
              value={addressDetails?.firstName || ''}
              onChange={(e) => changeAddressDetails(e)}
              variant="outlined"
              size="small"
              inputProps={{
                autoComplete: 'given-name',
              }}
            />
            <TextField
              name="lastName"
              label="Last Name"
              fullWidth
              value={addressDetails?.lastName || ''}
              onChange={(e) => changeAddressDetails(e)}
              variant="outlined"
              size="small"
              inputProps={{
                autoComplete: 'family-name',
              }}
            />
          </InlineInput>
          <TextField
            name="companyName"
            label="Company Name"
            value={addressDetails?.companyName || ''}
            onChange={(e) => changeAddressDetails(e)}
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: 'organization',
            }}
          />
          <TextField
            name="email"
            label="Email"
            value={accountDetails?.email || ''}
            onChange={(e) => changeAccountDetails(e)}
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: 'email',
            }}
          />
          <TextField
            name="phone"
            label="Phone Number"
            value={accountDetails?.phone || ''}
            onChange={(e) => changeAccountDetails(e)}
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: 'tel',
            }}
          />
          <h3>Your Discord Account</h3>
          <InlineInput>
            <img src={accountDetails?.profilePicture || ''} alt="discord logo" />
            {accountDetails?.username || ''}
          </InlineInput>
        </AccountTextFields>
      </AccountSection>
      <AccountSection>
        <h2>Address Details</h2>
        <AccountTextFields>
          <TextField
            name="line1"
            label="Address Line 1"
            onChange={(e) => changeAddressDetails(e)}
            value={addressDetails?.line1 || ''}
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: 'address-line1',
            }}
          />
          <TextField
            name="line2"
            label="Address Line 2"
            onChange={(e) => changeAddressDetails(e)}
            value={addressDetails?.line2 || ''}
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: 'address-line2',
            }}
          />
          <TextField
            name="city"
            label="City"
            onChange={(e) => changeAddressDetails(e)}
            value={addressDetails?.city || ''}
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: 'address-level2',
            }}
          />
          <InlineInput>
            <TextField
              name="postcode"
              label="Postcode"
              onChange={(e) => changeAddressDetails(e)}
              value={addressDetails?.postcode || ''}
              variant="outlined"
              size="small"
              inputProps={{
                autoComplete: 'postal-code',
              }}
            />
          </InlineInput>
          <TextField
            label="Country"
            name="country"
            select
            size="small"
            value={addressDetails?.country || ''}
            onChange={(e) => changeAddressDetails(e)}
            variant="outlined"
            inputProps={{
              autoComplete: 'country-name',
            }}
          >
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
        </AccountTextFields>
      </AccountSection>
      {/* <AccountSection>
        <h2>Notification Settings</h2>
        <AccountCheckboxes>
          <FormControlLabel
            control={(
              <Checkbox
                name="allowEmailNotifications"
                checked={accountDetails?.allowEmailNotifications || false}
                onChange={(e) => changeAccountDetails({
                  target: {
                    name: 'allowEmailNotifications',
                    value: e.target.checked,
                  },
                })}
              />
            )}
            label="Email"
          />
          <FormControlLabel
            control={(
              <Checkbox
                name="allowDiscordNotifications"
                checked={accountDetails?.allowDiscordNotifications || false}
                onChange={(e) => changeAccountDetails({
                  target: {
                    name: 'allowDiscordNotifications',
                    value: e.target.checked,
                  },
                })}
              />
            )}
            label="Discord"
          />
          <FormControlLabel
            control={(
              <Checkbox
                name="allowSMSNotifications"
                checked={accountDetails?.allowSMSNotifications || false}
                onChange={(e) => changeAccountDetails({
                  target: {
                    name: 'allowSMSNotifications',
                    value: e.target.checked,
                  },
                })}
              />
            )}
            label="SMS"
          />
        </AccountCheckboxes>
      </AccountSection> */}
      <AccountSection>
        <h2>Your Subscription</h2>
        <a
          href={`https://whop.com/hub/${accountDetails?.whopMembershipId}`}
          target="_blank"
          rel="noreferrer"
        >
          Manage your subscription on Whop
        </a>
      </AccountSection>
      <Button
        onClick={() => {
          window.location.href = '/logout';
        }}
      >
        Log out
      </Button>
    </PageWrapper>
  );
}

const AccountSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  border: 1px solid rgba(224, 224, 224, 1);
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
  h3 {
    margin-bottom: 0;
  }
  a {
    color: blue;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const AccountTextFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 700px;
`;

// const AccountCheckboxes = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

const InlineInput = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-weight: bold;
  align-items: center;

  img {
    border: 1px solid rgba(224, 224, 224, 1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
