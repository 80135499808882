import React, { useState } from 'react';

import {
  Dashboard,
  Inventory,
  AddShoppingCart,
  LocationOn,
  // NotificationsNone,
  Settings,
  Menu,
  ChevronRight,
  CreditCard,
} from '@mui/icons-material';

// import { Badge } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import LogoSrc from '../../images/logo.svg';

import { useAuth } from '../../providers/AuthProvider';

export default function Topbar() {
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { user } = useAuth();

  const handleSettingsClick = () => {
    // if url contains "/reshipper/" then navigate to reshipper settings
    // else navigate to admin settings
    if (window.location.href.includes('/reshipper/')) {
      navigate('/reshipper/account');
    } else navigate('/account');
  };

  // const handleNotificationsClick = () => {};

  const handleMobileMenuClick = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <>
      <Navbar>
        <NavbarLeft onClick={() => navigate('/')}>
          <Logo src={LogoSrc} alt="logo" />
          Slick Reship
        </NavbarLeft>
        <NavbarRight>
          <DesktopView>
            {/* <Badge color="primary">
              <NotificationsNone onClick={() => handleNotificationsClick()} />
            </Badge> */}

            <Settings onClick={() => handleSettingsClick()} />
          </DesktopView>
          <MobileView onClick={() => handleMobileMenuClick()}>
            <Menu />
          </MobileView>
        </NavbarRight>
      </Navbar>

      {showMobileMenu && (
        <MobileMenuWrapper onClick={() => handleMobileMenuClick()}>
          <MobileMenu>
            <MobileMenuItem onClick={() => navigate('/')}>
              <Dashboard />
              <MobileMenuItemText>Dashboard</MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem onClick={() => navigate('/inventory')}>
              <Inventory />
              <MobileMenuItemText>Inventory</MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem onClick={() => navigate('/delivery')}>
              <AddShoppingCart />
              <MobileMenuItemText>Expecting New Parcel</MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem onClick={() => navigate('/addresses')}>
              <LocationOn />
              <MobileMenuItemText>Addresses</MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem onClick={() => navigate('/payments')}>
              <CreditCard />
              <MobileMenuItemText>Payments</MobileMenuItemText>
            </MobileMenuItem>
            <MobileMenuItem onClick={() => handleSettingsClick()}>
              <Settings />
              <MobileMenuItemText>Settings</MobileMenuItemText>
            </MobileMenuItem>

            {user?.roles.length > 1 && <DashboardHr />}

            {user?.roles.includes('admin') && (
              <MobileMenuItem onClick={() => navigate('/admin')}>
                <MobileMenuItemText>Admin Dashboard</MobileMenuItemText>
                <ChevronRight />
              </MobileMenuItem>
            )}
            {user?.roles.includes('reshipper') && (
              <MobileMenuItem onClick={() => navigate('/reshipper/account')}>
                <MobileMenuItemText>Reshipper Dashboard</MobileMenuItemText>
                <ChevronRight />
              </MobileMenuItem>
            )}
          </MobileMenu>
        </MobileMenuWrapper>
      )}
    </>
  );
}

const DashboardHr = styled.hr`
  border: 1px solid #e0e0e0;
  margin: 10px 25px;
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
  cursor: pointer;
`;

const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #134ae5;
  color: #fff;
  border-bottom: 1px solid #e0e0e0;
  font-weight: bold;
  font-size: 28px;

  @media (max-width: 480px) {
    padding: 0.5rem;
  }
`;

const NavbarLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const NavbarRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const MobileView = styled.div`
  display: none;
  margin: auto 0;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const DesktopView = styled.div`
  display: flex;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 66px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

const MobileMenu = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 0 0px 5px 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  z-index: 9999;
`;

const MobileMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const MobileMenuItemText = styled.div`
  margin-left: 0.5rem;
  font-size: 16px;
  font-weight: bold;
`;
