import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '../../functions/request';

// Fetch customer account details
const fetchAccountDetails = async () => request('/customer/account');

// Update customer account details
const updateAccountDetails = async (accountData) => request('/customer/account', {
  method: 'POST',
  body: accountData,
});

export const useAccountDetails = () => useQuery(['accountDetails'], fetchAccountDetails);

export const useUpdateAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAccountDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['accountDetails'] });
    },
  });
};
