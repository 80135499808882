import React from 'react';
import styled from 'styled-components';
import FeaturedInfo from '../../components/customer/FeaturedInfo';
import ExpectingParcels from '../../components/customer/ExpectingParcels';
import NewMessages from '../../components/customer/NewMessages';
import PageWrapper from '../../components/global/PageWrapper';

import useExpectedDeliveryDetails from '../../hooks/customer/useDashboardData';
import { useInventoryDetails } from '../../hooks/customer/useInventoryData';

function Home() {
  const { data: expectedParcels, isLoading } = useExpectedDeliveryDetails();
  const { data: inventory } = useInventoryDetails();

  return (
    <PageWrapper
      children={(
        <>
          <FeaturedInfo
            expectedParcels={expectedParcels}
            inventory={inventory}
          />
          <HomeWidgets>
            <ExpectingParcels expectedParcels={expectedParcels} isLoading={isLoading} />
            <NewMessages />
          </HomeWidgets>
        </>
      )}
    />
  );
}

export default Home;

const HomeWidgets = styled.div`
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 30px;
`;
