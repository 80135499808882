import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { Button } from '@mui/material';

import { toast } from 'react-toastify';

import { request } from '../../functions/request';
import PageWrapper from '../../components/global/PageWrapper';

export default function Delivery() {
  const navigate = useNavigate();

  const { deliveryId } = useParams();

  const [delivery, setDelivery] = useState();

  // get the delivery details from the id in the url
  useEffect(() => {
    request(`/customer/delivery/${deliveryId}`).then((data) => {
      setDelivery(data);
    });
  }, [deliveryId]);

  const handleArchiveDelivery = () => {
    request(`/customer/delivery/${deliveryId}/archive`, {
      method: 'POST',
    })
      .then(() => {
        toast.success('Delivery archived');
        // sleep for 1 second then navigate back to the deliveries page
        setTimeout(() => {
          navigate('/');
        }, 1000);
      })
      .catch(() => {
        toast.error('Failed to archive delivery');
      });
  };

  const handleContactReshipper = () => {
    request(`/customer/discord/${delivery.reshipperId}/discordChat`)
      .then((data) => {
        window.open(`https://discord.com/channels/1026169667447963738/${data.channelId}`, '_blank');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <PageWrapper
      pageTitle={`Delivery#${delivery?._id.slice(-5)} Expected for ${
        delivery?.address?.reshipper
      } (${delivery?.address?.country})`}
      children={(
        <PageSplit>
          <ExpectedDeliveryTiles>
            {delivery?.notes && (
            <>
              <h2>Order Notes</h2>
              <OrderNotes>{delivery?.notes}</OrderNotes>
            </>
            )}
            <h2>Expected Parcels</h2>
            {delivery?.parcels.map((parcel) => (
              <ExpectedParcelTile key={parcel._id}>
                {`${parcel.quantity} parcel${parcel.quantity > 1 ? 's' : ''} for '${
                  parcel.nameOnParcel
                }' expected on ${new Date(parcel.dateExpected.split('T')[0]).toDateString()}`}
              </ExpectedParcelTile>
            ))}
          </ExpectedDeliveryTiles>
          <DeliveryActions>
            <h2>Delivery Actions</h2>
            <DeliveryActionButtons>
              <Button variant="contained" onClick={handleContactReshipper}>
                Contact Reshipper
              </Button>
              <Button variant="contained" disabled>
                Report Issue
              </Button>
              <Button variant="contained" onClick={handleArchiveDelivery}>
                Archive Delivery
              </Button>
            </DeliveryActionButtons>
          </DeliveryActions>
        </PageSplit>
      )}
    />
  );
}

const DeliveryActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ExpectedParcelTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  padding-left: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
`;

const OrderNotes = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 200px;
`;

const DeliveryActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 10px;
  }
`;

const ExpectedDeliveryTiles = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 10px;
  }
`;

const PageSplit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap-reverse;
`;
