import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request, requestFormBody } from '../../functions/request';

// Fetch customer account details
const fetchInventoryDetails = async () => request('/customer/inventory');

const sendToPlatformMutation = async (data) => requestFormBody('/customer/actions/sendToPlatform', {
  method: 'POST',
  body: data,
});

const markAsCompleteMutation = async (data) => request('/customer/actions/markAsComplete', {
  method: 'POST',
  body: data,
});

const sendToAddressMutation = async (data) => requestFormBody('/customer/actions/sendToAddress', {
  method: 'POST',
  body: data,
});

export const useInventoryDetails = () => useQuery(['inventoryDetails'], fetchInventoryDetails);

export const useMarkAsCompleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: markAsCompleteMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryDetails'] });
    },
  });
};

export const useSendToAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendToAddressMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryDetails'] });
    },
  });
};
export const useSendToPlatformMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: sendToPlatformMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['inventoryDetails'] });
    },
  });
};
