import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '../../functions/request';

// Fetch customer account details
const fetchInventoryDetails = async () => request('/reshipper/inventory');

export function useInventoryDetails() {
  return useQuery(['reshipperInventoryDetails'], fetchInventoryDetails);
}

const markAsSentMutation = async (data) => {
  request('/reshipper/actions/markAsSent', {
    method: 'POST',
    body: data,
  });
};

const markAsArchivedMutation = async (data) => {
  request('/reshipper/inventory/archive', {
    method: 'POST',
    body: data,
  });
};

export const useMarkAsSentMutation = () => {
  const queryClient = useQueryClient();

  useMutation({
    mutationFn: markAsSentMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reshipperInventoryDetails'] });
    },
  });
};

export const useMarkAsArchivedMutation = () => {
  const queryClient = useQueryClient();

  useMutation({
    mutationFn: markAsArchivedMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reshipperInventoryDetails'] });
    },
  });
};
