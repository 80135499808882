/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useMemo } from 'react';

import { TextField, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import styled from 'styled-components';
import PageWrapper from '../../components/global/PageWrapper';
import ParcelActionMenu from '../../components/reshipper/ParcelActionMenu';
import { useInventoryDetails } from '../../hooks/reshipper/useInventoryData';

export default function Inventory() {
  //
  // States
  //
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const [windowSize, setWindowSize] = useState({ innerWidth: 0, innerHeight: 0 });
  const [selectedItems, setSelectedItems] = useState([]);

  //
  // Data sources
  //
  const { data, isLoading } = useInventoryDetails();

  //
  // Page Fuctions
  //
  // Window resizing for hiding columns
  const handleWindowResize = () => {
    const { innerWidth, innerHeight } = window;
    setWindowSize({ innerWidth, innerHeight });
  };

  const dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  window.addEventListener('resize', handleWindowResize);

  useMemo(() => {
    handleWindowResize();
  }, []);

  // Filtering functionality
  useEffect(() => {
    if (!data) return;

    setRows(
      data
        .filter((item) => {
          if (item._id.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          if (item.name.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          if (item.sku.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          if (item.size.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          if (item.condition.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          return false;
        })
        .map((item) => {
          const daysHeld = item.dateSent
            ? 'n/a'
            : dateDiffInDays(new Date(item.created), new Date());

          return {
            _id: item._id,
            id: item._id.slice(-5),
            name: item?.name,
            sku: item?.sku,
            size: item?.size,
            status: item?.status,
            condition: item?.condition,
            daysHeld,
            customer: item.customerName,
            customerId: item.customerId,
          };
        }),
    );
  }, [search, data]);

  const columns = [
    {
      field: 'name',
      headerName: 'Product Name',
      flex: 1.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sku',
      headerName: 'Sku',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'customer',
      headerName: 'customer',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'daysHeld',
      headerName: 'Days Held',
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <PageWrapper
      pageTitle="Reshipper Inventory"
      sidebarType="reshipper"
      children={
        <>
          <SearchBarRow>
            <SearchBoxContainer>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                className="textField"
              />
              <SearchResultsNumber>{rows.length} Results</SearchResultsNumber>
            </SearchBoxContainer>
            <ParcelActionMenu selectedItems={selectedItems} />
          </SearchBarRow>

          {isLoading && (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          )}
          {!isLoading && (
            <InventoryTableContainer>
              <DataGrid
                rows={rows}
                columns={columns}
                sx={{
                  display: 'grid',
                  backgroundColor: 'white',
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;',
                }}
                checkboxSelection
                columnVisibilityModel={{
                  sku: windowSize.innerWidth > 1024,
                  size: windowSize.innerWidth > 780,
                  daysHeld: windowSize.innerWidth > 1024,
                  condition: windowSize.innerWidth > 780,
                }}
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) => selectedIDs.has(row.id.toString()));
                  setSelectedItems(selectedRowData);
                }}
              />
            </InventoryTableContainer>
          )}
        </>
      }
    />
  );
}

// align all items to the top of the page
const LoadingContainer = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

const SearchBoxContainer = styled.div`
  max-width: 500px;
  display: flex;
  white-space: nowrap;
  align-items: flex-end;
  padding-top: 5px;
  jusitfy-content: space-between;
`;

const SearchResultsNumber = styled.div`
  margin-left: 10px;
  color: #555;
`;

const InventoryTableContainer = styled.div`
  margin-top: 10px;
  max-height: 90%;
  flex-grow: 1;
`;

const SearchBarRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;
