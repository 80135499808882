import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import ExpectedParcelTile from './ExpectedParcelTile';

export default function ExpectedParcels({ expectedParcels, isLoading }) {
  return (
    <ExpectingParcel>
      <TitleBar>
        <ExpectingParcelTitle>Expected Deliveries</ExpectingParcelTitle>
        <Link to="/reshipper/delivered" className="text-link">
          <Button variant="contained">
            <Add />
            I&apos;ve Received a Parcel
          </Button>
        </Link>
      </TitleBar>

      <ExpectingParcelList>
        {isLoading && <CircularProgress />}
        {!isLoading && expectedParcels.length < 1 && 'You are not expecting any parcels.'}
        {!isLoading &&
          expectedParcels.map((delivery) => <ExpectedParcelTile delivery={delivery} />)}
      </ExpectingParcelList>
    </ExpectingParcel>
  );
}

ExpectedParcels.propTypes = {
  expectedParcels: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      customerName: PropTypes.string,
      parcels: PropTypes.arrayOf(
        PropTypes.shape({
          quantity: PropTypes.number,
          dateExpected: PropTypes.string,
        }),
      ),
    }),
  ),
  isLoading: PropTypes.bool,
};

ExpectedParcels.defaultProps = {
  expectedParcels: [],
  isLoading: true,
};

const ExpectingParcel = styled.div`
  flex: 1;
  min-width: 425px;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ExpectingParcelTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  display: flex;
  margin-bottom: 5px;
`;

const ExpectingParcelList = styled.div`
  margin: 0 0 15px;
  padding-left: 0px;
  list-style: none;
  max-height: 500px;
  overflow-y: auto;
`;
