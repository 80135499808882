function requiredEnv(env) {
  throw new TypeError(`The ${env} environment variable is strictly required.`);
}

function assertPath(path) {
  const type = typeof path;
  if (type !== 'string') {
    throw new TypeError(`The path should be a string, instead received a ${type}`);
  }
}

export const hosts = {
  API_URL: process.env.REACT_APP_API_URL || requiredEnv('REACT_APP_API_URL'),
};

async function parseResponse(res) {
  // If a body response exists, parse anx extract the possible properties

  if (res.status === 204) {
    return {};
  }

  if (res.status === 200) {
    return res.json();
  }

  if (res.status === 400) {
    const error = await res.json();
    throw new Error(`${res.status} : ${error.message}`);
  }

  if (res.status === 401) {
    window.location.replace('/logout');
  }

  if (res.status === 403) {
    window.location.replace('/');
  }

  throw new Error(`${res.status} : ${res.statusText}`);
}

export function request(path, options = {}) {
  const {
    headers,
    query = null,
    method = 'GET',
    body,
    host = hosts.API_URL,

    ...extraOpts
  } = options;
  assertPath(path);

  // Compose the request configuration object
  const reqOptions = {
    credentials: 'include',
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    ...extraOpts,
  };

  // If a body object is passed, automatically stringify it.
  if (body) {
    reqOptions.body = typeof body === 'object' ? JSON.stringify(body) : body;
  }

  let queryString = '';
  if (query) {
    // Convert to encoded string and prepend with ?
    queryString = new URLSearchParams(query).toString();
    queryString = queryString && `?${queryString}`;
  }

  return fetch(`${host}${path}${queryString}`, reqOptions).then(parseResponse);
}

export function requestFormBody(path, options = {}) {
  const {
    headers,
    query = null,
    method = 'GET',
    body,
    host = hosts.API_URL,
    ...extraOpts
  } = options;
  assertPath(path);

  // Compose the request configuration object
  const reqOptions = {
    credentials: 'include',
    method,
    body,
    headers: {
      ...headers,
    },
    ...extraOpts,
  };

  // If a body object is passed, automatically stringify it.

  let queryString = '';
  if (query) {
    // Convert to encoded string and prepend with ?
    queryString = new URLSearchParams(query).toString();
    queryString = queryString && `?${queryString}`;
  }

  return fetch(`${host}${path}${queryString}`, reqOptions).then(parseResponse);
}
