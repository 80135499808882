import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import LogoImg from '../../images/logo_blue.svg';
import DiscordLogoImg from '../../images/DiscordLogoWhite.svg';

export default function Login() {
  // get paramaes from url
  const urlParams = new URLSearchParams(window.location.search);
  const failed = urlParams.get('failure');

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
  };

  return (
    <PageContainer>
      <PageSplit>
        <LoginSection>
          {!failed && (
            <>
              <Logo src={LogoImg} />
              <h1>Sign in to Slick Reship</h1>
              <h4>Buy Beyond Borders</h4>
              <LoginButton onClick={handleLogin}>
                Login with <DiscordLogo src={DiscordLogoImg} />
              </LoginButton>
              <Disclaimer>
                By clicking &apos;Login with Discord&apos;, you acknowledge that you have read and
                agree to our <a href="https://www.slickreship.com/tos">Terms of Service</a> and <a href="https://www.slickreship.com/privacy">Privacy Policy</a>.
              </Disclaimer>
            </>
          )}
          {failed && (
            <FailureText>
              {failed === 'NOSUB' && (
                <Column>
                  You must have a subscription to use Slick Reship.
                  <Button
                    variant="contained"
                    sx={{
                      paddingRight: '50px',
                      paddingLeft: '50px',
                    }}
                    onClick={() => {
                      window.open('https://whop.com/slickreship/', '_blank');
                      window.location.href = 'https://app.slickreship.com/logout';
                    }}
                  >
                    Subscribe
                  </Button>
                </Column>
              )}
              {failed === 'NOACCESS' && (
                <>
                  You do not have access to the site yet.
                  <Button
                    variant="contained"
                    sx={{
                      paddingRight: '50px',
                      paddingLeft: '50px',
                    }}
                    onClick={() => {
                      window.location.href = 'https://app.slickreship.com/logout';
                    }}
                  >
                    Logout
                  </Button>
                </>
              )}
              {failed === 'UNKNOWN' && (
                <>
                  We encountered an unknown error trying to log you in! Please refresh your page and
                  try again. The issue has been logged and we&apos;re tirelessly working on fixing
                  it :D
                  <Button
                    variant="contained"
                    sx={{
                      paddingRight: '50px',
                      paddingLeft: '50px',
                    }}
                    onClick={() => {
                      window.location.href = 'https://app.slickreship.com/logout';
                    }}
                  >
                    Return to Login
                  </Button>
                </>
              )}
            </FailureText>
          )}
        </LoginSection>
        <HeaderMarginCompensationDiv />
      </PageSplit>
    </PageContainer>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const Disclaimer = styled.div`
  font-size: 0.8rem;
  margin-top: 10px;
  max-width: 450px;
  color: gray;
  text-align: center;
  margin-top: 100px;

  a {
    text-decoration: underline;
    color: gray;
  }
`;

const HeaderMarginCompensationDiv = styled.div`
  height: 75px;
`;

const FailureText = styled.p`
  color: red;
  font-size: 1.2rem;
`;

const DiscordLogo = styled.img`
  height: 30px;
  margin-right: 10px;
`;

const LoginButton = styled.button`
  gap: 10px;
  margin-top: 40px;
  background-color: #5865f2;
  color: white;
  border-radius: 5px;
  padding: 5px 40px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const LoginSection = styled.div`
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0;
  }

  h4 {
    font-weight: 400;
    margin-top: 5px;
  }
   
  @media (max-width: 1024px) {
    h1 {
      font-size: 26px;
    }
    h4 {
      font-size: 14px;
    }
`;

const PageSplit = styled.div`
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  height: 100px;
`;
