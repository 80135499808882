import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import {
  ChatBubbleOutlineOutlined,
  HighlightOffOutlined,
  ReceiptOutlined,
} from '@mui/icons-material';
import { useArchiveMutation } from '../../hooks/customer/usePaymentData';

import { request } from '../../functions/request';

export default function PaymentTile({ payment }) {
  const contactCustomer = (customerId) => {
    request(`/reshipper/discord/${customerId}/discordChat`)
      .then((data) => {
        window.open(`https://discord.com/channels/1026169667447963738/${data.channelId}`, '_blank');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const archiveMutation = useArchiveMutation();

  const archivePayment = (paymentToBeArchived) => {
    archiveMutation.mutate(
      { paymentId: paymentToBeArchived._id },
      {
        onSuccess: () => {
          toast.success('Payment archived successfully');
        },
        onError: (err) => {
          toast.error(err.message);
        },
      },
    );
  };

  return (
    <PaymentCard>
      <Stacked>
        <PaymentCardTitle>{payment.name}</PaymentCardTitle>
        <PaymentCardSubtitle>{payment.date?.split('T')[0]}</PaymentCardSubtitle>
      </Stacked>

      <PendingPaymentAmount>£{payment.amount}</PendingPaymentAmount>
      {payment.status === 'Pending' && (
        <PendingPaymentInfo>
          {(24 - Math.abs(Date.parse(payment.date) - Date.now()) / 36e5).toFixed(1)} hrs
        </PendingPaymentInfo>
      )}
      {payment.status !== 'Pending' && <PendingPaymentInfo>{payment.status}</PendingPaymentInfo>}

      <PaymentCardActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => contactCustomer(payment.customerId)}
        >
          <Tooltip title="Contact Reshipper">
            <ChatBubbleOutlineOutlined />
          </Tooltip>
        </Button>
        {payment.status === 'Pending' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(payment.url, '_blank')}
          >
            PAY
          </Button>
        )}
        {payment.status === 'PAID' && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(
              `${process.env.REACT_APP_API_URL}/customer/payments/${payment._id}/receipt`,
              '_blank',
            )}
          >
            <Tooltip title="Download Reciept">
              <ReceiptOutlined />
            </Tooltip>
          </Button>
        )}
        {payment.status === 'Void' && (
          <Button variant="contained" color="primary" onClick={() => archivePayment(payment)}>
            <Tooltip title="Archive Payment">
              <HighlightOffOutlined />
            </Tooltip>
          </Button>
        )}
      </PaymentCardActions>
    </PaymentCard>
  );
}

PaymentTile.propTypes = {
  payment: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.string,
    amount: PropTypes.number,
    status: PropTypes.string,
    customerId: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentCardSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const PaymentCard = styled.div`
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  width: 100%;
`;

const PaymentCardTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const PendingPaymentAmount = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #f00;
`;

const PaymentCardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const PendingPaymentInfo = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
