import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useNavigate, Outlet } from 'react-router-dom';
import useLocalStorage from './UseLocalStorage';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [user, setUser] = useLocalStorage('user', {
    authenticated: false,
  });
  const navigate = useNavigate();
  const auth = useMemo(
    () => ({
      user,
      login: (data) => {
        setUser(data);
        navigate('/');
      },
      logout: () => {
        setUser({ authenticated: false });
        navigate('/login');
      },
    }),
    [user, setUser, navigate],
  );
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthProvider;

export function ProtectedRoute({ userRole }) {
  const { user } = useAuth();

  if (!user.authenticated) {
    return <Navigate to="/login" />;
  }

  if (!user.roles.includes(userRole)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}

ProtectedRoute.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export function LogInRoute({ children }) {
  const { user } = useAuth();
  return user.authenticated ? <Navigate to="/" /> : children;
}

LogInRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
