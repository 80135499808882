import React from 'react';

import {
  Dashboard,
  Inventory,
  AddShoppingCart,
  LocationOn,
  ChevronRight,
  CreditCard,
} from '@mui/icons-material';

import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../providers/AuthProvider';

export default function CustomerSidebar() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const currentPageName = window.location.pathname.substring(1);

  let role = 'Customer';
  if (user.roles.includes('reshipper')) {
    role = 'Reshipper';
  }
  if (user.roles.includes('admin')) {
    role = 'Admin';
  }

  const handleAdminPageButtonClick = () => {
    navigate('/admin');
  };

  const handleReshipperPageButtonClick = () => {
    navigate('/reshipper');
  };

  return (
    <Sidebar>
      <ProfileSection>
        <ProfilePictureImg src={user.profilePicture} alt="profile picture" />
        <DiscordName>{user.username}</DiscordName>
        <UserRank>{role}</UserRank>
      </ProfileSection>
      <NavigationSection>
        <NavigationItem
          onClick={() => navigate('/')}
          className={currentPageName === '' && 'selected'}
        >
          <Dashboard />
          <NavigationItemText>Dashboard</NavigationItemText>
        </NavigationItem>

        <NavigationItem
          onClick={() => navigate('/inventory')}
          className={currentPageName.includes('inventory') && 'selected'}
        >
          <Inventory />
          <NavigationItemText>Inventory</NavigationItemText>
        </NavigationItem>

        <NavigationItem
          onClick={() => navigate('/delivery')}
          className={currentPageName.includes('delivery') && 'selected'}
        >
          <AddShoppingCart />
          <NavigationItemText>Expecting A Delivery</NavigationItemText>
        </NavigationItem>

        <NavigationItem
          onClick={() => navigate('/addresses')}
          className={currentPageName.includes('addresses') && 'selected'}
        >
          <LocationOn />
          <NavigationItemText>Addresses</NavigationItemText>
        </NavigationItem>

        <NavigationItem
          onClick={() => navigate('/payments')}
          className={currentPageName.includes('payments') && 'selected'}
        >
          <CreditCard />
          <NavigationItemText>Payments</NavigationItemText>
        </NavigationItem>
      </NavigationSection>
      {user.roles.includes('admin') && (
        <DashboardLink onClick={() => handleAdminPageButtonClick()}>
          Admin Dashboard
          <ChevronRight />
        </DashboardLink>
      )}
      {user.roles.includes('reshipper') && (
        <DashboardLink onClick={() => handleReshipperPageButtonClick()}>
          Reshipper Dashboard
          <ChevronRight />
        </DashboardLink>
      )}
    </Sidebar>
  );
}

const DashboardLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  height: 50px;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  &:hover {
    background-color: #e5e5e5;
  }
`;

const Sidebar = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: #333;
  border-right: 1px solid #e0e0e0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-width: 225px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileSection = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem 1rem 2rem;
  border-bottom: 1px solid #9f9f9f;
`;

const ProfilePictureImg = styled.img`
  border-radius: 50%;
  width: 75px;
  height: 75px;
`;

const DiscordName = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
`;

const UserRank = styled.p`
  font-size: 1rem;
  margin-top: 0;
`;

const NavigationSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-top: 5vh;
  margin-bottom: auto;
`;

const NavigationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;
  width: 100%;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #e0e0e0;
  }

  &.selected {
    background-color: #e0e0e0; 
  }

  &.disabled {
    background-color: grey;
  }

  svg {
    margin-left: 1.2rem
`;

const NavigationItemText = styled.p`
  font-size: 1rem;
  margin-left: 0.5rem;
`;
