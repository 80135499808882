import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { request } from '../../functions/request';

const fetchAddresses = async () => {
  const data = await request('/customer/address/claimed');
  return data.sort((a, b) => {
    if (a.country < b.country) {
      return -1;
    }
    if (a.country > b.country) {
      return 1;
    }
    return 0;
  });
};

export default function useNewDelivery() {
  const [selectedAddress, setSelectedAddress] = useState();
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [parcel, setParcel] = useState();
  const [quantity, setQuantity] = useState(1);
  const [expectedParcels, setExpectedParcels] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [notes, setNotes] = useState('');

  const { data: addressData, isLoading: addressesLoading } = useQuery(
    ['claimedAddressData'],
    fetchAddresses,
  );

  useState(() => {
    setTotalQuantity(expectedParcels.reduce((acc, cur) => acc + cur.quantity, 0));
  }, [expectedParcels]);

  return {
    selectedAddress,
    setSelectedAddress,
    selectedDate,
    setSelectedDate,
    parcel,
    setParcel,
    quantity,
    setQuantity,
    expectedParcels,
    setExpectedParcels,
    addressData,
    totalQuantity,
    notes,
    setNotes,
    addressesLoading,
  };
}
