import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@mui/material';
import PageWrapper from '../../components/global/PageWrapper';
import PaymentTile from '../../components/customer/PaymentTile';

import 'react-toastify/dist/ReactToastify.css';

import {
  usePendingPaymentsData,
  usePaymentHistoryData,
} from '../../hooks/customer/usePaymentData';

export default function Addresses() {
  const pendingPayments = usePendingPaymentsData();
  const paymentHistory = usePaymentHistoryData();

  return (
    <PageWrapper
      pageTitle="Payments"
      sidebarType="customer"
      children={(
        <PageWidgets>
          <WidgetSection>
            <h2>Pending Payments</h2>
            <PaymentGrid>
              {pendingPayments.isLoading && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}
              {pendingPayments.isSuccess &&
                pendingPayments.data.length === 0 &&
                'You are all caught up!'}
              {pendingPayments.isSuccess &&
                pendingPayments.data.map((payment) => (
                  <PaymentTile key={payment._id} payment={payment} />
                ))}
            </PaymentGrid>
          </WidgetSection>
          <WidgetSection>
            <h2>Payment History</h2>
            <PaymentGrid>
              {paymentHistory.isLoading && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}
              {paymentHistory.isSuccess &&
                paymentHistory.data.length === 0 &&
                'You have not made any payments yet!'}
              {paymentHistory.isSuccess &&
                paymentHistory.data.map((payment) => (
                  <PaymentTile key={payment._id} payment={payment} />
                ))}
            </PaymentGrid>
          </WidgetSection>
        </PageWidgets>
      )}
    />
  );
}

const PageWidgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap-reverse;
`;

const WidgetSection = styled.div`
  flex: 1;
  width: 50%;
`;

const PaymentGrid = styled.div`
  min-width: 280px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: none;
  overflow-y: none;
`;
