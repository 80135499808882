import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '../../functions/request';

const fetchActionData = async () => request('/reshipper/actions/pending');

const markAsSentMutation = async (actionId) => request('/reshipper/actions/markAsSent', {
  method: 'POST',
  body: { actionId },
});

const fetchItemData = async (query) => {
  if (!query.queryKey[1].itemIds) return null;

  const data = request('/reshipper/inventory/getItems', {
    method: 'POST',
    body: {
      itemIds: query.queryKey[1].itemIds,
    },
  });

  return data;
};

const markAsArrivedMutation = async (actionId) => request('/reshipper/actions/markAsArrived', {
  method: 'POST',
  body: { actionId },
});

export const useActionData = () => useQuery(['actionData'], fetchActionData);

export const useMarkAsSentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: markAsSentMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['actionData'] });
    },
  });
};

export const useMarkAsArrivedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: markAsArrivedMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['actionData'] });
    },
  });
};

export const useItemData = (itemIds) => useQuery(['itemData', { itemIds }], fetchItemData);
