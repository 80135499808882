import React from 'react';
import styled from 'styled-components';

export default function WidgetSm() {
  return (
    <NewMessages>
      {/* <NewMessagesTitle>Notifications</NewMessagesTitle>
      <NewMessagesList>You do not have any new notifications.</NewMessagesList> */}
    </NewMessages>
  );
}

const NewMessages = styled.div`
  flex: 1;
  min-width: 425px;
`;

// const NewMessagesTitle = styled.div`
//   font-size: 22px;
//   font-weight: 600;
//   display: flex;
//   margin-bottom: 5px;
// `;

// const NewMessagesList = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 1rem;
//   max-height: 500px;
//   overflow-y: auto;
// `;
