import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default function NewParcelTile({ parcel }) {
  return (
    <ParcelTileList>
      <NewParcelLeftContainer>
        <NewParcelTitleRow>
          <NewParcelTitle>
            (
            {parcel?.quantity}
            x)
            {' '}
            {parcel?.nameOnParcel}
            {' '}
            {parcel.notes && ' | '}
            {parcel?.notes}
          </NewParcelTitle>
        </NewParcelTitleRow>
        <span>
          Expected:
          {new Date(parcel?.dateExpected).toLocaleDateString()}
        </span>
      </NewParcelLeftContainer>
    </ParcelTileList>
  );
}

NewParcelTile.propTypes = {
  parcel: PropTypes.shape({
    quantity: PropTypes.number,
    nameOnParcel: PropTypes.string,
    notes: PropTypes.string,
    dateExpected: PropTypes.string,
  }).isRequired,
};

const ParcelTileList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
`;

const NewParcelLeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const NewParcelTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const NewParcelTitle = styled.div`
  font-weight: 600;
  margin-right: 20px;
`;
