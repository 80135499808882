import React, { useEffect } from 'react';
import { useAuth } from '../../providers/AuthProvider';

export default function Login() {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  });

  return (
    <div>
      <h1>Goodbye</h1>
    </div>
  );
}
