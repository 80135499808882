import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Autocomplete, Button, TextField, CircularProgress,
} from '@mui/material';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import { request } from '../../functions/request';

export default function PaymentPopup({ setPopupOpen }) {
  const [paymentButtonDisabled, setPaymentButtonDisabled] = useState(false);
  const [foundCustomers, setFoundCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customer, setCustomer] = useState('');
  const [amount, setAmount] = useState(0.0);

  useEffect(() => {
    if (!customerSearch) return;

    request('/reshipper/delivery/findCustomer', {
      method: 'POST',
      body: { customer: customerSearch },
    })
      .then((data) => {
        const usernames = data.map((user) => ({ username: user.username, _id: user._id }));

        setFoundCustomers(usernames);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [customerSearch]);

  const createPayment = () => {
    // check if customer and amount fields are populated
    setPaymentButtonDisabled(true);

    if (!customer || !amount) {
      toast.error('Please fill out all fields');
      setPaymentButtonDisabled(false);
      return;
    }

    if (amount <= 0) {
      toast.error('Amount must be greater than 0');
      setPaymentButtonDisabled(false);
      return;
    }

    request('/reshipper/payments/create', {
      method: 'POST',
      body: {
        customerId: foundCustomers.filter((user) => user.username === customer)[0]._id,
        amount,
      },
    })
      .then(() => {
        toast.success('Payment created successfully');
        setAmount(0.0);
        setCustomer('');
      })
      .catch(() => {
        toast.error('Failed to create payment. Is your Stripe Connect ID set?');
      })
      .finally(() => {
        setPopupOpen(false);
        setPaymentButtonDisabled(false);
      });
  };

  return (
    <PopupBackground onClick={() => setPopupOpen(false)}>
      <Popup onClick={(e) => e.stopPropagation()}>
        <h2>New Payment</h2>
        <Autocomplete
          disablePortal
          id="customers"
          size="small"
          inputValue={customerSearch}
          onInputChange={(e, value) => setCustomerSearch(value)}
          value={customer}
          onChange={(e, newValue) => setCustomer(newValue)}
          options={foundCustomers?.map((user) => user.username)}
          noOptionsText="Start typing to search for customers"
          renderInput={(params) => <TextField {...params} label="Customer*" />}
        />
        <InputStyle>
          <Label>Amount</Label>
          <CurrencyInput
            name="amount"
            prefix="£"
            placeholder="How much do you want to charge the customer?"
            defaultValue={0}
            decimalsLimit={2}
            decimalSeparator="."
            groupSeparator=","
            value={amount}
            onValueChange={(value) => setAmount(value)}
          />
        </InputStyle>
        <ButtonRow>
          <PaymentInfo>Payment Requests are valid for 24 hours</PaymentInfo>
          <Button
            variant="contained"
            onClick={() => createPayment()}
            disabled={paymentButtonDisabled}
          >
            {paymentButtonDisabled ? (
              <CircularProgress size="24px" sx="padding: 0 58px" />
            ) : (
              'Request Payment'
            )}
          </Button>
        </ButtonRow>
      </Popup>
    </PopupBackground>
  );
}

PaymentPopup.propTypes = {
  setPopupOpen: PropTypes.func.isRequired,
};

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Popup = styled.div`
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  width: 400px;
`;

const InputStyle = styled.div`
  margin-top: 10px;
  display: flex;
  input {
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    font-size: 16px;
    padding: 8.5px 14px;
    display: flex;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const Label = styled.div`
  position: absolute;
  font-size: 12px;
  margin-left: 10px;
  margin-top: -5px;
  background-color: white;
  padding: 0px 5px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const PaymentInfo = styled.div`
  font-size: 12px;
  margin: 0px auto;
`;
