import React from 'react';

import PageWrapper from '../../components/global/PageWrapper';

export default function Admin() {
  return (
    <PageWrapper
      pageTitle="Admin Dashboard"
      sidebarType="admin"
      children={(
        <>
          <p>Use the buttons on the left to navigate</p>
          <p>I&apos;ll put a dashboard with an overview of all our stuff here eventually</p>
        </>
      )}
    />
  );
}
