import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import ReactCountryFlag from 'react-country-flag';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import styled from 'styled-components';
import { request } from '../../functions/request';

import countryData from '../../data/countries';

export default function AddressAcordian({
  address, setReshipper, editable, name,
}) {
  const [_address, setAddress] = useState(address);
  const [regions, setRegions] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loadingRegions, setLoadingRegions] = useState(true);
  const params = useParams();

  useEffect(() => {
    if (!_address?.country) return;
    setRegions(countryData.find((country) => country.countryName === _address?.country).regions);

    // wait 1 second then set loading regions to false
    setTimeout(() => {
      setLoadingRegions(false);
    }, 1000);
  }, [_address]);

  const handleChange = (event) => {
    setAddress({ ..._address, [event.target.name]: event.target.value });
    setUnsavedChanges(true);
  };

  const discardChanges = () => {
    setAddress(address);
    toast.success('Address changes discarded!');
    setUnsavedChanges(false);
  };

  const disallowCountry = () => {
    request(`/admin/reshipper/${params.reshipperId}/disallowCountry`, {
      method: 'POST',
      body: {
        addressId: _address._id,
      },
    })
      .then((response) => {
        toast.success('Country disallowed successfully');
        setReshipper(response);
      })
      .catch(() => {
        toast.error('Error disallowing country');
      });
  };

  const submitChanges = () => {
    request(`/admin/reshipper/${params.reshipperId}/updateAddress/${_address._id}`, {
      method: 'POST',
      body: _address,
    })
      .then((response) => {
        toast.success('Address updated successfully');
        setReshipper(response);
        setUnsavedChanges(false);
      })
      .catch(() => {
        toast.error('Error updating address');
      });
  };

  let countryShortcode;
  try {
    countryShortcode = countryData.find(
      (e) => e.countryName === _address?.country,
    ).countryShortcode;
  } catch (error) {
    countryShortcode = 'NP';
  }

  return (
    <Accordion sx={{ borderRadius: '5px', border: '1px solid lightgrey' }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div>
          <ReactCountryFlag
            countryCode={countryShortcode}
            svg
            style={{
              height: '35px',
              width: '35px',
              marginRight: '10px',
            }}
          />
          {name || `${_address?.country} #${_address?._id?.slice(-4)}`}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <AccordianDetailsWrapper>
          <InlineInput>
            <TextField
              name="firstName"
              label="First Name"
              value={_address?.firstName || ''}
              onChange={handleChange}
              disabled={!editable}
              fullWidth
              variant="outlined"
              size="small"
            />
            <TextField
              name="lastName"
              label="Last Name"
              value={_address?.lastName || ''}
              onChange={handleChange}
              disabled={!editable}
              fullWidth
              variant="outlined"
              size="small"
            />
          </InlineInput>
          <TextField
            name="line1"
            label="Address Line 1"
            value={_address?.line1 || ''}
            onChange={handleChange}
            disabled={!editable}
            variant="outlined"
            size="small"
          />
          <TextField
            name="line2"
            label="Address Line 2"
            value={_address?.line2 || ''}
            onChange={handleChange}
            disabled={!editable}
            variant="outlined"
            size="small"
          />
          <TextField
            name="city"
            label="City"
            value={_address?.city || ''}
            onChange={handleChange}
            disabled={!editable}
            variant="outlined"
            size="small"
          />
          <TextField
            name="postcode"
            label="Postcode"
            value={_address?.postcode || ''}
            onChange={handleChange}
            disabled={!editable}
            variant="outlined"
            size="small"
          />
          <TextField
            name="country"
            label="Country"
            disabled
            value={_address?.country || ''}
            variant="outlined"
            size="small"
          />
          <TextField
            name="state"
            label="State"
            disabled={!editable}
            select
            value={loadingRegions ? '' : _address?.state}
            onChange={handleChange}
            variant="outlined"
            size="small"
            sx={{ backgroundColor: 'white', minWidth: '223px' }}
          >
            {regions?.map((region) => (
              <MenuItem key={region} value={region}>
                {region}
              </MenuItem>
            ))}
          </TextField>
          <InlineInput>
            <TextField
              id="phonePrefix"
              name="phonePrefix"
              label="Phone Prefix"
              value={_address?.phonePrefix}
              onChange={handleChange}
              disabled={!editable}
              variant="outlined"
              size="small"
            />
            <TextField
              id="phone"
              name="phone"
              label="Phone Number"
              value={_address?.phone}
              onChange={handleChange}
              disabled={!editable}
              fullWidth
              variant="outlined"
              size="small"
            />
          </InlineInput>
          <TextField
            name="bannedSites"
            label="Banned Sites"
            value={_address?.bannedSites}
            onChange={handleChange}
            disabled={!editable}
            variant="outlined"
            size="small"
          />
          <TextField
            name="notes"
            label="Notes"
            value={_address?.notes}
            onChange={handleChange}
            disabled={!editable}
            variant="outlined"
            size="small"
          />

          <FormControlLabel
            control={(
              <Checkbox
                checked={_address?.claimable}
                id="claimable"
                name="claimable"
                disabled={!editable}
                onChange={(event) => handleChange({
                  target: {
                    name: 'claimable',
                    value: event.target.checked,
                  },
                })}
              />
            )}
            label="Claimable"
          />
          {editable && (
            <AddressChangesButtons>
              <Button variant="contained" color="error" onClick={disallowCountry}>
                Disallow Country
              </Button>

              <Button
                variant="outlined"
                color="primary"
                disabled={!unsavedChanges}
                onClick={discardChanges}
              >
                Discard Changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!unsavedChanges}
                onClick={submitChanges}
              >
                Save Changes
              </Button>
            </AddressChangesButtons>
          )}
        </AccordianDetailsWrapper>
      </AccordionDetails>
    </Accordion>
  );
}

AddressAcordian.propTypes = {
  address: PropTypes.object.isRequired,
  setReshipper: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

const AddressChangesButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const InlineInput = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const AccordianDetailsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
