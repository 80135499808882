import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '../../functions/request';

// Fetch customer account details
const fetchAccountDetails = async () => request('/customer/payments/pending');

const fetchPaymentHistoryQuery = async () => request('/customer/payments/history');

const archiveMutation = async (data) => request('/customer/payments/archive', {
  method: 'POST',
  body: data,
});

export const usePendingPaymentsData = () => useQuery(['pendingPayments'], fetchAccountDetails);

export const usePaymentHistoryData = () => useQuery(['paymentHistory'], fetchPaymentHistoryQuery);

export const useArchiveMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: archiveMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['paymentHistory'] });
    },
  });
};
