/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

import { ToastContainer } from 'react-toastify';

import styled from 'styled-components';

import CustomerSidebar from '../customer/CustomerSidebar';
import AdminSidebar from '../admin/AdminSidebar';
import ReshipperSidebar from '../reshipper/ReshipperSidebar';
import CustomerTopbar from '../customer/CustomerTopbar';
import AdminTopbar from '../admin/AdminTopbar';
import ReshipperTopbar from '../reshipper/ReshipperTopbar';

import 'react-toastify/dist/ReactToastify.css';

function PageWrapper({ children, pageTitle, titleChildren, sidebarType = 'customer' }) {
  return (
    <PageWrapperDiv>
      {sidebarType === 'customer' && <CustomerTopbar />}
      {sidebarType === 'admin' && <AdminTopbar />}
      {sidebarType === 'reshipper' && <ReshipperTopbar />}
      <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} />
      <Container>
        <Sidebar>
          {sidebarType === 'customer' && <CustomerSidebar />}
          {sidebarType === 'admin' && <AdminSidebar />}
          {sidebarType === 'reshipper' && <ReshipperSidebar />}
        </Sidebar>
        <MainContent>
          {(titleChildren || pageTitle) && (
            <PageTitle>
              <h1>{pageTitle}</h1>
              {titleChildren}
            </PageTitle>
          )}
          <PageContent>{children}</PageContent>
        </MainContent>
      </Container>
    </PageWrapperDiv>
  );
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  titleChildren: PropTypes.node,
  sidebarType: PropTypes.string,
};

PageWrapper.defaultProps = {
  pageTitle: '',
  titleChildren: null,
  sidebarType: 'customer',
};

const Sidebar = styled.div`
  height: calc(100vh - 68px);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const PageWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
`;

const MainContent = styled.div`
  flex: 4;
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const PageTitle = styled.div`
  color: #333;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 480px) {
    h1 {
      font-size: 1.5rem;
    }
  }
`;

const PageContent = styled.div`
  flex: 1;
`;

export default PageWrapper;
