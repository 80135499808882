import React, {
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Visibility } from '@mui/icons-material';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import PageWrapper from '../../components/global/PageWrapper';

import { useAccountData, useUpdateAccountDetails } from '../../hooks/reshipper/useAccountData';
import countryData from '../../data/countries';

export default function ReshipperAccount() {
  const [accountDetails, setAccountDetails] = useState({});
  const [addressDetails, setAddressDetails] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [reshippingStatus, setReshippingStatus] = useState({});

  const { data: accountData, isLoading } = useAccountData();

  useEffect(() => {
    if (isLoading) return;

    setAccountDetails(accountData);

    accountData?.reshippingAddresses?.map((address) => {
      const temp = address;
      temp.regions = countryData.find((country) => country.countryName === address.country).regions;
      return temp;
    });

    setAddressDetails(accountData?.reshippingAddresses);
    setReshippingStatus(accountData?.reshippingStatus);
  }, [accountData, isLoading]);

  const updateMutation = useUpdateAccountDetails();

  const saveAccountDetails = () => {
    updateMutation.mutate(
      {
        reshippingStatus,
        reshippingAddresses: addressDetails,
      },
      {
        onSuccess: () => {
          toast.success('Account details updated successfully');
          setUnsavedChanges(false);
        },
        onError: () => {
          toast.error('Error updating account details');
        },
      },
    );
  };

  const changeAddressDetails = (e, address) => {
    setUnsavedChanges(true);

    setAddressDetails((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((item) => item._id === address._id);

      temp[index] = {
        ...temp[index],
        [e.target.name]: e.target.value,
      };

      return temp;
    });
  };

  return (
    <PageWrapper
      pageTitle="Your Reshipper Profile"
      sidebarType="reshipper"
      titleChildren={(
        <Button
          variant="contained"
          sx={{ maxHeight: '36px', margin: 'auto 0 auto auto' }}
          onClick={saveAccountDetails}
          disabled={!unsavedChanges}
        >
          Save Changes
        </Button>
      )}
      children={(
        <>
          <AccountSection>
            <h2>Stripe Details</h2>
            <AccountTextFields>
              <InlineInput>
                <TextField
                  name="stripeConnectId"
                  fullWidth
                  label="Stripe Connect Id"
                  value={accountDetails?.stripeConnectId || ''}
                  variant="outlined"
                  size="small"
                  disabled
                />
                <Button variant="contained" sx={{ minWidth: '200px' }} className="viewButtonBig">
                  View Account
                </Button>
                <Button variant="contained" className="viewButtonSmall">
                  <Visibility />
                </Button>
              </InlineInput>
              <TextField
                name="stripeIdentityId"
                label="Stripe Identity Id"
                value={accountDetails?.stripeIdentityId || ''}
                variant="outlined"
                size="small"
                disabled
              />
            </AccountTextFields>
          </AccountSection>
          <AccountSection>
            <h2>Holiday Details</h2>
            <AccountTextFields>
              <TextField
                name="reshippingStatus"
                label="Reshipping Status"
                select
                value={reshippingStatus?.status || ''}
                onChange={(e) => {
                  setUnsavedChanges(true);
                  setReshippingStatus({
                    ...reshippingStatus,
                    canReceive: e.target.value.includes('R') || e.target.value === 'AVAILABLE',
                    status: e.target.value,
                  });
                }}
                variant="outlined"
                size="small"
              >
                <MenuItem value="AVAILABLE">Available</MenuItem>
                <MenuItem value="AWAY-R">Away - Can receive parcels</MenuItem>
                <MenuItem value="AWAY">Away - Can NOT receive parcels</MenuItem>
              </TextField>
              {reshippingStatus?.status?.includes('AWAY') && (
                <InlineInput>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Away From"
                      name="awayFrom"
                      value={reshippingStatus?.awayFrom || null}
                      onChange={(newValue) => {
                        setUnsavedChanges(true);
                        setReshippingStatus({
                          ...reshippingStatus,
                          awayFrom: newValue,
                        });
                      }}
                      size="small"
                      renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                    />
                    <DatePicker
                      label="Away Until"
                      name="awayUntil"
                      value={reshippingStatus?.awayUntil || null}
                      size="small"
                      fullWidth
                      onChange={(newValue) => {
                        setUnsavedChanges(true);
                        setReshippingStatus({
                          ...reshippingStatus,
                          awayUntil: newValue,
                        });
                      }}
                      renderInput={(params) => <TextField size="small" fullWidth {...params} />}
                    />
                  </LocalizationProvider>
                </InlineInput>
              )}
            </AccountTextFields>
          </AccountSection>
          {isLoading && <CircularProgress />}
          {!isLoading && addressDetails?.length === 0 && (
            <p>
              You don&apos;t seem to have any countries allocated to you. Contact an Admin to add
              you to the reship whitelist for your country.
            </p>
          )}
          {!isLoading &&
            addressDetails?.map((address) => (
              <AccountSection key={address._id}>
                <h2>
                  Address Details for
                  {' '}
                  {address.country}
                  {' '}
                  #
                  {address._id.slice(-4)}
                </h2>
                <AccountTextFields>
                  <InlineInput>
                    <TextField
                      name="firstName"
                      label="First Name"
                      fullWidth
                      value={address?.firstName || ''}
                      onChange={(e) => changeAddressDetails(e, address)}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        autoComplete: 'given-name',
                      }}
                    />
                    <TextField
                      name="lastName"
                      label="Last Name"
                      fullWidth
                      value={address?.lastName || ''}
                      onChange={(e) => changeAddressDetails(e, address)}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        autoComplete: 'family-name',
                      }}
                    />
                  </InlineInput>
                  <TextField
                    name="line1"
                    label="Address Line 1"
                    onChange={(e) => changeAddressDetails(e, address)}
                    value={address?.line1 || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      autoComplete: 'address-line1',
                    }}
                  />
                  <TextField
                    name="line2"
                    label="Address Line 2"
                    onChange={(e) => changeAddressDetails(e, address)}
                    value={address?.line2 || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      autoComplete: 'address-line2',
                    }}
                  />
                  <TextField
                    name="city"
                    label="City"
                    onChange={(e) => changeAddressDetails(e, address)}
                    value={address?.city || ''}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      autoComplete: 'address-level2',
                    }}
                  />
                  <InlineInput>
                    <TextField
                      name="postcode"
                      label="Postcode"
                      onChange={(e) => changeAddressDetails(e, address)}
                      value={address?.postcode || ''}
                      variant="outlined"
                      size="small"
                      inputProps={{
                        autoComplete: 'postal-code',
                      }}
                    />
                  </InlineInput>
                  <TextField
                    label="Country"
                    name="country"
                    size="small"
                    value={address?.country || ''}
                    variant="outlined"
                    inputProps={{
                      autoComplete: 'country-name',
                    }}
                  />
                  <TextField
                    name="state"
                    label="State"
                    select
                    value={address.state || ''}
                    onChange={(e) => changeAddressDetails(e, address)}
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: 'white',
                      minWidth: '223px',
                    }}
                  >
                    {address.regions.map((region) => (
                      <MenuItem key={address._id} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </TextField>
                  <InlineInput>
                    <TextField
                      name="phonePrefix"
                      label="Phone Prefix"
                      value={address?.phonePrefix || ''}
                      onChange={(e) => changeAddressDetails(e, address)}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      name="phone"
                      label="Phone Number"
                      fullWidth
                      value={address?.phone || ''}
                      onChange={(e) => changeAddressDetails(e, address)}
                      variant="outlined"
                      size="small"
                    />
                  </InlineInput>
                  <TextField
                    name="bannedSites"
                    label="Banned Sites (separate with commas)"
                    onChange={(e) => changeAddressDetails(e, address)}
                    value={address?.bannedSites || ''}
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    name="notes"
                    label="Notes"
                    onChange={(e) => changeAddressDetails(e, address)}
                    value={address?.notes || ''}
                    variant="outlined"
                    size="small"
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={address?.claimable}
                        id="claimable"
                        name="claimable"
                        value={address?.claimable}
                        onChange={(_e) => changeAddressDetails(
                          {
                            target: {
                              value: _e.target.checked,
                              name: 'claimable',
                            },
                          },
                          address,
                        )}
                      />
                    )}
                    label="Claimable"
                  />
                </AccountTextFields>
              </AccountSection>
            ))}
          <Button
            onClick={() => {
              window.location.href = '/logout';
            }}
          >
            Log out
          </Button>
        </>
      )}
    />
  );
}

const AccountSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  box-shadow: 0px 0px 15px -10px rgb(0 0 0 / 75%);
  border: 1px solid rgba(224, 224, 224, 1);
  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
  h3 {
    margin-bottom: 0;
  }
`;

const AccountTextFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 700px;
`;

const InlineInput = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  font-weight: bold;
  align-items: center;

  img {
    border: 1px solid rgba(224, 224, 224, 1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @media (max-width: 762px) {
    .viewButtonBig {
      display: none;
    }
    .viewButtonSmall {
      display: flex;
    }
  }

  @media (min-width: 762px) {
    .viewButtonBig {
      display: flex;
    }
    .viewButtonSmall {
      display: none;
    }
  }
`;
