import React, { useState } from 'react';

import {
  Button, CircularProgress, Skeleton, TextField,
} from '@mui/material';
import { Description } from '@mui/icons-material';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

import PageWrapper from '../../components/global/PageWrapper';
import ActionTile from '../../components/reshipper/ActionTile';

import { request } from '../../functions/request';
import {
  useActionData,
  useMarkAsArrivedMutation,
  useMarkAsSentMutation,
  useItemData,
} from '../../hooks/reshipper/useActionsData';

export default function Addresses() {
  const [selectedAction, setSelectedAction] = useState(null);

  const pendingActionData = useActionData();
  const itemData = useItemData();

  const contactCustomer = async (customerId) => {
    request(`/reshipper/discord/${customerId}/discordChat`)
      .then((data) => {
        window.open(`https://discord.com/channels/1026169667447963738/${data.channelId}`, '_blank');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const markActionAsSent = async (actionId) => {
    useMarkAsSentMutation.mutate(actionId, {
      onSuccess: () => {
        toast.success('Action marked as sent successfully');
      },
      onError: () => {
        toast.error('Failed to mark action as sent');
      },
    });
  };

  const markActionAsArrived = async (actionId) => {
    useMarkAsArrivedMutation.mutate(actionId, {
      onSuccess: () => {
        toast.success('Action marked as sent successfully');
      },
      onError: () => {
        toast.error('Failed to mark action as sent');
      },
    });
  };

  const findItems = async (itemIds) => {
    itemData.refetch(
      { itemIds },
      {
        onError: () => {
          toast.error('Failed to load items');
        },
      },
    );
  };

  return (
    <PageWrapper
      pageTitle="Actions"
      sidebarType="reshipper"
      children={(
        <PageWidgets>
          <WidgetSection>
            <TitleBar>
              <Title>Pending Actions</Title>
            </TitleBar>
            <PaymentGrid>
              {pendingActionData.isLoading && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}

              {pendingActionData.isError && 'Failed to load pending payments.'}

              {!pendingActionData.isLoading &&
                pendingActionData.data.length === 0 &&
                'You are all caught up!'}

              {!pendingActionData.isLoading &&
                pendingActionData.data.map((action) => (
                  <ActionTile
                    key={action._id}
                    action={action}
                    active={action === selectedAction}
                    onClick={() => {
                      findItems(action.itemIds);
                      setSelectedAction(action);
                    }}
                  />
                ))}
            </PaymentGrid>
          </WidgetSection>
          <WidgetSection>
            <TitleBar>
              <Title>Action</Title>
            </TitleBar>

            <ActionDetails>
              {!selectedAction && 'Select an action to see its details.'}
              {selectedAction && (
                <>
                  <ActionButtons>
                    {selectedAction.status === 'Pending' && (
                      <Button
                        variant="contained"
                        onClick={() => markActionAsSent(selectedAction._id)}
                      >
                        Mark as Sent
                      </Button>
                    )}
                    {selectedAction.status === 'In Transit' && (
                      <Button
                        variant="contained"
                        onClick={() => markActionAsArrived(selectedAction._id)}
                      >
                        Confirm Arrived
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      onClick={() => contactCustomer(selectedAction.customerId)}
                    >
                      Contact Customer
                    </Button>
                  </ActionButtons>

                  <TextFieldList>
                    <TextField
                      label="Action"
                      fullWidth
                      value={selectedAction?.action || ''}
                      variant="outlined"
                      size="small"
                    />
                    <TextField
                      label="Notes"
                      name="notes"
                      value={selectedAction?.notes || ''}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  </TextFieldList>

                  <h3>Attached Files</h3>
                  <AttachedFiles>
                    {selectedAction?.files.map((file) => (
                      <File>
                        <Description onClick={() => window.open(file, '_blank')} />
                        File #
                        {(selectedAction?.files.indexOf(file) ?? -1) + 1}
                        {' '}
                        {file.split('.')[file.split('.').length - 1]}
                      </File>
                    ))}
                  </AttachedFiles>

                  <h3>Items</h3>
                  <Items>
                    {itemData.isLoading && <CircularProgress />}

                    {itemData.isError && 'Failed to load items.'}

                    {!itemData.isLoading && itemData.data.length === 0 && 'No items found.'}

                    {!itemData.isLoading &&
                      itemData.data.map((item, index) => (
                        <Item>
                          <div>
                            (
                            {index + 1}
                            )
                            {item.name}
                          </div>
                          {item.sku && <div>{item.sku}</div>}
                          {item.size && <div>{item.size}</div>}
                        </Item>
                      ))}
                  </Items>
                </>
              )}
            </ActionDetails>
          </WidgetSection>
        </PageWidgets>
      )}
    />
  );
}

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 22px;
  font-weight: 600;
  display: flex;
  margin-bottom: 10px;
`;

const PageWidgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const WidgetSection = styled.div`
  flex: 1;
  width: 50%;
`;

const ActionDetails = styled.div`
  min-width: 280px;
  display: flex;
  flex-direction: column;
`;

const TextFieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PaymentGrid = styled.div`
  min-width: 280px;
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  max-height: none;
  overflow-y: none;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
`;

const AttachedFiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
`;

const File = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  svg {
    cursor: pointer;
    width: 35px;
    height: 35px;
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;
