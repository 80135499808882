import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import styled from 'styled-components';

export default function ActionTile({ action, active }) {
  return (
    <ActionCard className={active && 'active'}>
      <Stacked>
        <ActionCardTitle>
          <ActionCardSubsection>
            <b>{action.action.replaceAll('_', ' ')}</b> -
          </ActionCardSubsection>
          <ActionCardSubsection>{action.customerName}</ActionCardSubsection>
        </ActionCardTitle>
        <ActionCardSubtitle>
          <Chip label={action.status} size="small" />
          {action.created?.split('T')[0]}{' '}
          <b>
            {action.itemIds.length} item
            {action.itemIds.length > 1 ? 's' : ''}
          </b>
        </ActionCardSubtitle>
      </Stacked>
    </ActionCard>
  );
}

ActionTile.propTypes = {
  action: PropTypes.shape({
    action: PropTypes.string,
    customerName: PropTypes.string,
    status: PropTypes.string,
    created: PropTypes.string,
    itemIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionCardSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ActionCard = styled.div`
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  display: flex;
  width: 100%;
  min-width: 280px;

  &:hover {
    cursor: pointer;

    transition: 0.2s;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
  }

  &.active {
    transition: 0.2s;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.75);
    border: 1px solid gray;
  }
`;

const ActionCardTitle = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
`;

const ActionCardSubsection = styled.div`
  font-size: 18px;
`;
