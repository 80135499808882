import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '../../functions/request';

const fetchAccountData = async () => request('/reshipper/account');

export const useAccountData = () => useQuery(['reshipperAccountData'], fetchAccountData);

const updateAccountDetailsMutation = async (data) => request('/reshipper/account', {
  method: 'PATCH',
  body: data,
});

export const useUpdateAccountDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAccountDetailsMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reshipperAccountData'] });
    },
  });
};
