import React, { useState, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Button,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogTitle,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import styled from 'styled-components';
import { Add, Save } from '@mui/icons-material';
import { request } from '../../functions/request';
import PageWrapper from '../../components/global/PageWrapper';
import ItemTile from '../../components/reshipper/ItemTile';

import { useAuth } from '../../providers/AuthProvider';

export default function Delivery() {
  const navigate = useNavigate();

  const { user } = useAuth();

  const [foundCustomers, setFoundCustomers] = useState([]);
  const [customerSearch, setCustomerSearch] = useState('');
  const [customer, setCustomer] = useState('');
  const [itemList, setItemList] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [item, setItem] = useState({
    condition: 'Perfect',
    addressId: '',
    notes: '',
    name: '',
    sku: '',
    size: '',
  });

  const [unidentifiableParcelDialogOpen, setUnidentifiableParcelDialogOpen] = useState(false);

  useEffect(() => {
    if (!customerSearch) return;

    request('/reshipper/delivery/findCustomer', {
      method: 'POST',
      body: { customer: customerSearch },
    })
      .then((data) => {
        const usernames = data.map((foundUser) => ({
          username: foundUser.username,
          _id: foundUser._id,
        }));

        setFoundCustomers(usernames);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [customerSearch]);

  const handleClose = () => {
    setUnidentifiableParcelDialogOpen(false);
  };

  const submitParcels = () => {
    if (itemList.length < 1) return;

    request('/reshipper/delivery/submitParcels', {
      method: 'POST',
      body: itemList,
    })
      .then(() => {
        toast.success('Parcels submitted. Please wait...');
        setTimeout(() => {
          navigate('/reshipper/inventory');
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const addParcels = () => {
    if (!customer) {
      toast.error('Please select a customer');
      return;
    }

    if (!item.name) {
      toast.error('Please enter the item name');
      return;
    }

    const customerId = foundCustomers.find(
      (foundCustomer) => foundCustomer.username === customer,
    )._id;

    setItemList([
      ...itemList,
      {
        id: itemList.length,
        ...item,
        customerId,
        quantity,
      },
    ]);
  };

  return (
    <PageWrapper
      pageTitle="Record Parcels in Delivery"
      sidebarType="reshipper"
      children={(
        <>
          <PageSplit>
            <PageSection>
              <h2>Quick Actions</h2>
              <ActionButtons>
                <Button variant="contained" onClick={() => setUnidentifiableParcelDialogOpen(true)}>
                  Unable to Identify Customer
                </Button>
                <Button variant="contained" color="error">
                  Report Issue
                </Button>
              </ActionButtons>

              <h2>Parcel Details</h2>
              <ParcelDetailsInput>
                <TextField
                  label="Address"
                  name="addressId"
                  value={item.addressId}
                  onChange={(e) => setItem({
                    ...item,
                    addressId: e.target.value,
                  })}
                  variant="outlined"
                  select
                  size="small"
                >
                  {user?.reshippingAddresses?.map((address) => (
                    <MenuItem value={address._id} key={address._id}>
                      {`${address.country} #${address._id.slice(-4)}`}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  disablePortal
                  id="customers"
                  size="small"
                  inputValue={customerSearch}
                  onInputChange={(event, value) => setCustomerSearch(value)}
                  value={customer}
                  onChange={(event, newValue) => setCustomer(newValue)}
                  options={foundCustomers?.map((foundUser) => foundUser.username)}
                  noOptionsText="Start typing to search for customers"
                  renderInput={(params) => <TextField {...params} label="Customer*" />}
                />
                <TextField
                  label="Item*"
                  value={item.name}
                  onChange={(e) => setItem({
                    ...item,
                    name: e.target.value,
                  })}
                  name="name"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <TextField
                  label="Sku"
                  name="sku"
                  value={item.sku}
                  onChange={(e) => setItem({
                    ...item,
                    sku: e.target.value,
                  })}
                  variant="outlined"
                  size="small"
                  sx={{ maxWidth: '250px' }}
                />
                <TextField
                  label="Size"
                  name="size"
                  value={item.size}
                  onChange={(e) => setItem({
                    ...item,
                    size: e.target.value,
                  })}
                  variant="outlined"
                  size="small"
                  sx={{ maxWidth: '250px' }}
                />
                <Flex>
                  <TextField
                    label="Condition"
                    name="condition"
                    value={item.condition}
                    onChange={(e) => setItem({
                      ...item,
                      condition: e.target.value,
                    })}
                    variant="outlined"
                    sx={{ maxWidth: '250px' }}
                    select
                    size="small"
                    aria-describedby="component-helper-text"
                  >
                    <MenuItem key="perfect" value="Perfect">
                      Perfect
                    </MenuItem>
                    <MenuItem key="DamagedBox" value="Damaged Box ">
                      Damaged Box
                    </MenuItem>
                    <MenuItem key="DamagedItem" value="Damaged Item">
                      Damaged Item
                    </MenuItem>
                    <MenuItem key="damaged" value="Damaged">
                      Damaged (Box and Item)
                    </MenuItem>
                    <MenuItem key="nobox" value="No Box">
                      Missing Original Box
                    </MenuItem>
                  </TextField>
                  {item?.condition !== 'Perfect' && (
                    <FormHelperText id="component-helper-text">
                      Please give a description of the damage in the notes
                    </FormHelperText>
                  )}
                </Flex>
                <TextField
                  label="Notes"
                  name="notes"
                  value={item.notes}
                  onChange={(e) => setItem({
                    ...item,
                    notes: e.target.value,
                  })}
                  multiline
                  rows={2}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <NewParcelButton>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    variant="outlined"
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      min: 1,
                      max: 250,
                    }}
                    type="number"
                    size="small"
                  />
                  <Button variant="contained" onClick={addParcels}>
                    <Add sx={{ paddingRight: '10px' }} />
                    Add Parcel{quantity > 1 && 's'}
                  </Button>
                </NewParcelButton>
              </ParcelDetailsInput>
            </PageSection>
            <PageSection>
              <h2>Parcel Overview</h2>

              {itemList.length === 0 && <p>You have not added any parcels yet.</p>}

              <ParcelList>
                {itemList.map((foundItem) => (
                  <ItemTile
                    key={foundItem.id}
                    item={foundItem}
                    customer={customer}
                    setItemList={setItemList}
                  />
                ))}
              </ParcelList>

              <NewParcelButton>
                <Button
                  variant="contained"
                  disabled={itemList.length === 0}
                  onClick={submitParcels}
                >
                  <Save sx={{ paddingRight: '10px' }} />
                  Confirm Parcels
                </Button>
              </NewParcelButton>
            </PageSection>
          </PageSplit>
          <Dialog onClose={handleClose} open={unidentifiableParcelDialogOpen}>
            <DialogTitle>Report Unidentifiable Parcel</DialogTitle>
            <ParcelDetailsInputDialog>
              <TextField
                label="Name on Parcel"
                name="nameOnParcel"
                fullWidth
                variant="outlined"
                size="small"
              />
              <TextField label="Item" name="item" fullWidth variant="outlined" size="small" />
              <TextField
                label="Notes"
                name="notes"
                multiline
                rows={2}
                maxRows={4}
                fullWidth
                variant="outlined"
                size="small"
              />
              <Button variant="contained">Submit</Button>
            </ParcelDetailsInputDialog>
          </Dialog>
        </>
      )}
    />
  );
}

const ParcelList = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Flex = styled('div')`
  display: flex;
  flex-direction: column;
`;

const NewParcelButton = styled('div')`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px 30px;
  flex-wrap: wrap;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
`;

const PageSection = styled.div`
  min-width: 250px;
  flex: 1;
  display: flex;
  flex-direction: column;

  h2 {
    margin-bottom: 10px;
  }
`;

const PageSplit = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
`;

const ParcelDetailsInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ParcelDetailsInputDialog = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
`;
