import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

export default function ExpectedParcelTile({ delivery }) {
  const navigate = useNavigate();
  const quantity = delivery?.parcels.reduce((acc, parcel) => acc + parcel.quantity, 0);
  return (
    <ExpectingParcelItem onClick={() => navigate(`/reshipper/delivery/${delivery._id}`)}>
      <ExpectingParcelTitle>Delivery #{delivery?._id.slice(-5)}</ExpectingParcelTitle>
      <ExpectingParcelInfoContainer>
        <ExpectingParcelInfo>
          <ExpectingParcelInfoTitle>Customer</ExpectingParcelInfoTitle>
          <span>{delivery?.customerName}</span>
        </ExpectingParcelInfo>
        <ExpectingParcelInfo>
          <ExpectingParcelInfoTitle>Quantity</ExpectingParcelInfoTitle>
          <span>{quantity}</span>
        </ExpectingParcelInfo>
        <ExpectingParcelInfo>
          <ExpectingParcelInfoTitle>Expected</ExpectingParcelInfoTitle>
          <span>{delivery?.parcels[0].dateExpected.split('T')[0]}</span>
        </ExpectingParcelInfo>
      </ExpectingParcelInfoContainer>
      <ChevronRight fontSize="large" />
    </ExpectingParcelItem>
  );
}

ExpectedParcelTile.propTypes = {
  delivery: PropTypes.shape({
    _id: PropTypes.string,
    customerName: PropTypes.string,
    parcels: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        dateExpected: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const ExpectingParcelItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
  cursor: pointer;
`;

const ExpectingParcelTitle = styled.div`
  display: flex;
  font-weight: 600;
  margin-right: 40px;
  gap: 20px;
`;

const ExpectingParcelInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
`;

const ExpectingParcelInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ExpectingParcelInfoTitle = styled.div`
  font-weight: 600;
`;
