import React from 'react';
import styled from 'styled-components';
import ReshipperFeaturedInfo from '../../components/reshipper/FeaturedInfo';
import ReshipperExpectingParcels from '../../components/reshipper/ExpectingParcels';
import ReshipperNewMessages from '../../components/reshipper/NewMessages';
import PageWrapper from '../../components/global/PageWrapper';
import useReshipperDashboardData from '../../hooks/reshipper/useDashboardData';

export default function Home() {
  const { data: expectedParcels, isLoading } = useReshipperDashboardData();

  return (
    <PageWrapper
      sidebarType="reshipper"
      children={(
        <>
          <ReshipperFeaturedInfo expectedParcels={expectedParcels} />
          <HomeWidgets>
            <ReshipperExpectingParcels expectedParcels={expectedParcels} isLoading={isLoading} />
            <ReshipperNewMessages />
          </HomeWidgets>
        </>
      )}
    />
  );
}

const HomeWidgets = styled.div`
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 30px;
`;
