import React from 'react';
import PropTypes from 'prop-types';
import { Add, Visibility, WarningAmber } from '@mui/icons-material';
import ReactCountryFlag from 'react-country-flag';

import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import countries from '../../data/countries';

function AddressTile({ address, onClick, claimed }) {
  let icon = <Add />;
  if (claimed) icon = <Visibility />;
  if (address.status !== 'AVAILABLE') icon = <WarningAmber />;

  let countryCode;
  try {
    countryCode = countries.find(
      (country) => country.countryName === address.country,
    ).countryShortcode;
  } catch {
    countryCode = '';
  }

  return (
    <Tooltip title={address.country} arrow>
      <AddressTileWrapper onClick={onClick}>
        <AddressTileContent>
          {icon}
          <div><b>{`${address.country}`}</b> {`(${address.reshipperName})`}</div>
          {countryCode && (
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              style={{
                height: '35px',
                width: '35px',
              }}
            />
          )}
        </AddressTileContent>
      </AddressTileWrapper>
    </Tooltip>
  );
}

AddressTile.propTypes = {
  address: PropTypes.shape({
    _id: PropTypes.string,
    country: PropTypes.string,
    reshipperName: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  claimed: PropTypes.bool,
};

AddressTile.defaultProps = {
  claimed: false,
};

export default AddressTile;

const AddressTileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 49%;
  cursor: pointer;

  @media (max-width: 1500px) {
    width: 100%;
  }
`;

const AddressTileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 5px 10px;
  min-width: 250px;
  flex: 1;
`;
