/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { MenuItem, Skeleton, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReshipperTile from '../../components/admin/ReshipperTile';
import PageWrapper from '../../components/global/PageWrapper';
import { request } from '../../functions/request';

export default function Reshippers() {
  const [reshippers, setReshippers] = useState([]);
  const [filteredReshippers, setFilteredReshippers] = useState([]);
  const [search, setSearch] = useState('');
  const [countryFilter, setCountryFilter] = useState('All');
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    request('/admin/getAllReshippers').then((res) => {
      setLoading(false);
      setReshippers(res);
      setFilteredReshippers(res);
    });
  }, []);

  useEffect(() => {
    if (countryFilter === 'All') setFilteredReshippers(reshippers);
    else {
      setFilteredReshippers(
        reshippers.filter((reshipper) =>
          reshipper.reshippingAddresses.find((address) => address.country === countryFilter),
        ),
      );
    }

    setFilteredReshippers((prev) =>
      prev.filter((reshipper) => reshipper.username.toLowerCase().includes(search.toLowerCase())),
    );
  }, [search, countryFilter, reshippers]);

  useEffect(() => {
    // get a unique list of countries in reshipper addresses
    reshippers.forEach((reshipper) => {
      reshipper.reshippingAddresses.forEach((address) => {
        if (countries.indexOf(address.country) === -1) {
          setCountries([...countries, address.country]);
        }
      });
    });
  }, [reshippers, countries]);

  return (
    <PageWrapper
      pageTitle="Reshipper Administration"
      sidebarType="admin"
      children={(
        <>
          <TitleBar>
            <h2>View All Reshippers</h2>
            <Filters>
              <TextField
                sx={{ minWidth: '150px' }}
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                className="textField"
              />
              <TextField
                sx={{ minWidth: '150px' }}
                id="countrySelect"
                label="Country"
                select
                defaultValue="All"
                size="small"
                onChange={(e) => {
                  setCountryFilter(e.target.value);
                }}
              >
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Filters>
          </TitleBar>
          <Grid container rowSpacing={0} columnSpacing={1} disableEqualOverflow>
            {loading ? (
              <LoadingSkeletons>
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
              </LoadingSkeletons>
            ) : null}
            {filteredReshippers.map((reshipper) => (
              <Grid xs={12} lg={6} key={reshipper._id}>
                <ReshipperTile reshipper={reshipper} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    />
  );
}

const Filters = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

  h2 {
    margin: 0;
  }
`;

const LoadingSkeletons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  span {
    width: 49% !important;
    height: 50px !important;
  }

  @media (max-width: 1024px) {
    span {
      width: 100% !important;
    }
  }
`;
