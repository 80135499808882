import React, { useState } from 'react';

import styled from 'styled-components';

import { Button, Skeleton } from '@mui/material';
import { Add } from '@mui/icons-material';
import PaymentPopup from '../../components/reshipper/PaymentPopup';
import PaymentTile from '../../components/reshipper/PaymentTile';
import PageWrapper from '../../components/global/PageWrapper';

import {
  usePendingPaymentsData,
  usePaymentHistoryData,
} from '../../hooks/reshipper/usePaymentData';

export default function Addresses() {
  const pendingPayments = usePendingPaymentsData();
  const paymentHistory = usePaymentHistoryData();

  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <PageWrapper
      pageTitle="Payments"
      sidebarType="reshipper"
      children={(
        <PageWidgets>
          {popupOpen && <PaymentPopup setPopupOpen={setPopupOpen} />}
          <WidgetSection>
            <TitleBar>
              <Title>Pending Payment</Title>
              <Button variant="contained" onClick={() => setPopupOpen(true)}>
                <Add />
                New Payment
              </Button>
            </TitleBar>

            <PaymentGrid>
              {pendingPayments.isLoading && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}

              {pendingPayments.isError && 'Failed to load pending payments :('}

              {!pendingPayments.isLoading && pendingPayments.data.length === 0 && (
                <p>You are all caught up!</p>
              )}
              {!pendingPayments.isLoading &&
                pendingPayments.data.map((payment) => (
                  <PaymentTile key={payment._id} payment={payment} />
                ))}
            </PaymentGrid>
          </WidgetSection>
          <WidgetSection>
            <h2>Payment History</h2>
            <PaymentGrid>
              {paymentHistory.isLoading && (
                <>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </>
              )}

              {!paymentHistory.isLoading &&
                paymentHistory.data.length === 0 &&
                'You have not received any payments yet!'}

              {paymentHistory.isError && 'Failed to load pending payment history :('}

              {!paymentHistory.isLoading &&
                paymentHistory.data.map((payment) => (
                  <PaymentTile key={payment._id} payment={payment} />
                ))}
            </PaymentGrid>
          </WidgetSection>
        </PageWidgets>
      )}
    />
  );
}

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 22px;
  font-weight: 600;
  display: flex;
  margin-bottom: 5px;
`;

const PageWidgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
`;

const WidgetSection = styled.div`
  flex: 1;
  width: 50%;
`;

const PaymentGrid = styled.div`
  min-width: 280px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  max-height: none;
  overflow-y: none;
`;
